import { useDispatch, useSelector } from 'react-redux';
import { onLoadRooms } from 'store';
import { roomApi } from "../api/";

export const useRoomStore = () => {
    const dispatch = useDispatch()
    const { rooms } = useSelector(state => state.rooms)



    const getRoomById = async (id) => {
        try {
            const { data } = await roomApi.get(`/getRoomById/${id}`);
            console.log("Datos de la reserva:", data);
            //Checar si el valor de data reservation trae algo
            return data;
        } catch (e) {
            console.log("Error", e);
        }
    };

    const getRooms = async () => {

        try {
            //console.log("ejecuta getSlots");
            const { data } = await roomApi.post('/rooms/getRooms');
            //console.log("datos de las rooms", data);
            dispatch(onLoadRooms(data));
            return data;

        } catch (e) {

        }

    }



    return {
        //Propiedades
        rooms,

        //Metodos
        getRoomById,
        getRooms
    }

}


