import { FC, useEffect, useRef, useState } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import HeroSearchFormSmall, {
  SearchTab,
} from "components/HeroSearchFormSmall/HeroSearchFormSmall";
import { useLocation } from "react-router-dom";
import AvatarDropdown from "./AvatarDropdown";
import MenuBar from "shared/MenuBar/MenuBar";
import { StaySearchFormFields } from "components/HeroSearchForm/type";
import { useNavigate } from "react-router-dom";
import { useReservationStore } from "hooks";
import CartaBebidas from "components/CartaBebidas/CartaBebidas";
import { Link } from "react-router-dom";

interface Header3Props {
  className?: string;
}

let WIN_PREV_POSITION = window.pageYOffset;

const Header3: FC<Header3Props> = ({ className = "" }) => {
  const headerInnerRef = useRef<HTMLDivElement>(null);
  //
  const [showHeroSearch, setShowHeroSearch] =
    useState<StaySearchFormFields | null>();
  //
  const [currentTab, setCurrentTab] = useState<SearchTab>("Stays");
  const navigate = useNavigate();
  const { car } = useReservationStore();


  //
  useOutsideAlerter(headerInnerRef, () => {
    setShowHeroSearch(null);
    setCurrentTab("Stays");
  });

  const handleRedirectRooms = () => {
    navigate("/rooms");
  };

  let location = useLocation();
  //

  useEffect(() => {
    setShowHeroSearch(null);
  }, [location]);

  // HIDDEN WHEN SCROLL EVENT
  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
    return () => {
      window.removeEventListener("scroll", handleEvent);
    };
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(handleHideSearchForm);
  };

  const handleHideSearchForm = () => {
    if (!document.querySelector("#nc-Header-3-anchor")) {
      return;
    }
    //
    let currentScrollPos = window.pageYOffset;
    if (
      WIN_PREV_POSITION - currentScrollPos > 100 ||
      WIN_PREV_POSITION - currentScrollPos < -100
    ) {
      setShowHeroSearch(null);
    } else {
      return;
    }
    WIN_PREV_POSITION = currentScrollPos;
  };

  // eslint-disable-next-line
  const renderHeroSearch = () => {
    return (
      <div
        className={`absolute inset-x-0 top-0 transition-all will-change-[transform,opacity] ${showHeroSearch
          ? "visible"
          : "-translate-x-0 -translate-y-[90px] scale-x-[0.395] scale-y-[0.6] opacity-0 invisible pointer-events-none"
          }`}
      >
        <div className={`w-full max-w-4xl mx-auto pb-6`}>
          <HeroSearchFormSmall
            defaultFieldFocus={showHeroSearch || undefined}
            onTabChange={setCurrentTab}
            defaultTab={currentTab}
          />
        </div>
      </div>
    );
  };

  // const renderButtonOpenHeroSearch = () => {
  //   return (
  //     <div
  //       className={`w-full relative flex items-center justify-between border border-neutral-200 dark:border-neutral-6000 rounded-full shadow hover:shadow-md transition-all ${
  //         showHeroSearch
  //           ? "-translate-x-0 translate-y-20 scale-x-[2.55] scale-y-[1.8] opacity-0 pointer-events-none invisible"
  //           : "visible"
  //       }`}
  //     >
  //       <div className="flex items-center font-medium text-sm">
  //         <span
  //           onClick={() => setShowHeroSearch("location")}
  //           className="block pl-5 pr-4 cursor-pointer py-3"
  //         >
  //           Location
  //         </span>
  //         <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700"></span>
  //         <span
  //           onClick={() => setShowHeroSearch("dates")}
  //           className="block px-4 cursor-pointer py-3 "
  //         >
  //           Check In
  //         </span>
  //         <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700"></span>
  //         <span
  //           onClick={() => {
  //             setShowHeroSearch("guests");
  //           }}
  //           className="block px-4 cursor-pointer font-normal py-3"
  //         >
  //           Add guests
  //         </span>
  //       </div>

  //       <div
  //         className="flex-shrink-0 ml-auto pr-2 cursor-pointer"
  //         onClick={() => setShowHeroSearch("location")}
  //       >
  //         <span className="w-8 h-8 flex items-center justify-center rounded-full bg-primary-6000  text-white">
  //           <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24">
  //             <path
  //               stroke="currentColor"
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth="1.5"
  //               d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
  //             ></path>
  //           </svg>
  //         </span>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <>
      <div className={`nc-MainNav1 bg-black sticky top-0 z-50 ${className}`}>
        <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
          <div className=" md:flex justify-center w-full items-center space-x-4  sm:space-x-10 flex-auto ">
            <Navigation />
            <Logo />
            <Link to={'/carta-bebidas'} ref={CartaBebidas} className="py-2 px-4 xl:px-5 hidden lg:inline-flex text-sm xl:text-base font-semibold text-purple-700 hover:text-purple-600 uppercase">Carta bebidas</Link>

          </div>

          {/* <div className="flex-[2] lg:flex-none mx-auto">
              <div className="hidden lg:block">
                {renderButtonOpenHeroSearch()}
              </div>
              <div className="lg:hidden w-full max-w-lg mx-auto">
                <HeroSearchForm2MobileFactory />
              </div>
              {renderHeroSearch()}
            </div> */}

          {/* NAV */}
          <div className=" hidden md:flex items-center space-x-1">
            <AvatarDropdown />
          </div>

          <div className="relative ">
            <MenuBar />
            {/* Muestra el contador de artículos solo si hay artículos en el carrito */}
            {car.length > 0 && (
              <div className="bg-purple-600 text-white w-5 h-5 text-sm rounded-full flex items-center justify-center absolute right-2 top-8">
                {car.length}
              </div>
            )}
          </div>

          <div>
            <button type="submit" onClick={handleRedirectRooms} className="hidden md:block bg-purple-700 text-white hover:text-gray-300 w-44 font-bold h-12 p-1 rounded-full uppercase">Reservar Sala</button>
          </div>
        </div>
        {/* </div>
        </div> */}
      </div>
    </>
  );
};

export default Header3;
