import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonReservarProps extends ButtonProps {}

const ButtonReservar: React.FC<ButtonReservarProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonReservar disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${className}`}
      {...args}
    />
  );
};

export default ButtonReservar;