import CommentListing from "components/CommentListing/CommentListing";


const Reviews = () => {


    return (
        <div className="border-4 rounded-3xl w-full border-purple-600">
            <div className="listingSection__wrap  flex border-purple-600 flex-col items-center">
                {/* HEADING */}
                <h2 className="text-4xl sm:text-3xl md:text-3xl lg:text-7xl xl:text-7xl text-center mt-10 font-extrabold">NUESTRAS RESEÑAS💬</h2>
                <span className='text-purple-600'>Asi lo disfrutaron...</span>

                {/* Content */}
                <div className="overflow-hidden relative">
                    <CommentListing className="" />
                </div>
            </div>
        </div>
    );
};

export default Reviews;