import CalendarReservation from "components/CalendarReservations/CalendarReservations";

import  {  useState } from "react";

import Sidebar from "shared/Sidebar/Sidebar";


const PageReservations = () => {
  const [sideMenuIsExpand, setSideMenuIsExpand] = useState(true);

  return (
    <div>
      {/* content */}
      
      <div
        className={`flex-1 min-h-screen transition-all duration-300 ease-in-out z-0 ${
          sideMenuIsExpand ? "md:ml-72" : "md:ml-20"
        }`}
      >
        
        <CalendarReservation />
      </div>

      <div className=" z-50">
        <Sidebar setExpand={setSideMenuIsExpand} />
      </div>
    </div>
  );
};
export default PageReservations;
