import {createSlice} from '@reduxjs/toolkit';

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users:[],
        isLoadingUsers: true,
      
    },
    reducers: {
        onCheckingUsers: (state) => {
        
            state.users = [];
            state.isLoadingUsers = false;
        },
        onEditing: (state) => {
            state.isLoadingUsers = false;
            state.users = [];
        
        },
       
        onLoadUsers(state,{ payload = [] } ) {
            
            state.isLoadingUsers = false;
            state.users = payload;
            // payload.forEach( slot => {
            //     const exists = state.slots.some( dbEvent => dbEvent.id === slot.id );
            //     if ( !exists ) {
            //         state.slots.push( slot )
            //     }
            // })
           
    },
    onLoadDeleteUser(state,{payload} ) {
            
        state.isLoadingUsers = false;
        state.users = state.users.filter(user => user._id !== payload);
       
},


        
       

       
    },
});

export const {
    onCheckingUsers,
    onEditing, 
    onLoadUsers,
    onLoadDeleteUser,
 } = usersSlice.actions;

