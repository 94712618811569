import React, { useState, Fragment, useEffect } from "react";
import { Transition, Dialog } from "@headlessui/react";
import NavMobile from "shared/Navigation/NavMobile";
import { useLocation, useNavigate, } from "react-router-dom";
import { useAuthStore } from "hooks";


export interface MenuBarProps {
  className?: string;
  iconClassName?: string;
  showIcon?: boolean;
  visible?: boolean;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  


}

const MenuBar: React.FC<MenuBarProps> = ({
  className = "p-2.5 rounded-lg text-neutral-700 dark:text-neutral-300 ",
  //iconClassName = "h-7 w-7",
  // ...
  showIcon = true, // Por defecto, se muestra el icono
  onClick,
  


}) => {
  // ...
  const [isVisable, setIsVisable] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { status } = useAuthStore();





  useEffect(() => {
    setIsVisable(false);
  }, [location]);

  const handleOpenMenu = () => {
    setIsVisable(true);
  }
  const handleCloseMenu = () => setIsVisable(false);
  const handleRedirectLogin = () => {
    // Redirige a la ruta "/login"
    navigate('/login');

  };


  const renderContent = () => {
    return (
      <Transition appear show={isVisable} as={Fragment}>
        <Dialog
          as="div"
          className="relative  z-50 overflow-hidden"
          onClose={handleCloseMenu}
        >
          <Transition.Child
            as={Fragment}
            enter=" duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave=" duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
          </Transition.Child>
          <div className="fixed inset-0">
            <div className="flex justify-end min-h-full ">
              <Transition.Child
                as={Fragment}
                enter="transition duration-100 transform"
                enterFrom="opacity-0 translate-x-56"
                enterTo="opacity-100 translate-x-0"
                leave="transition duration-150 transform"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-56"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden transition-all">
                  <NavMobile onClickClose={handleCloseMenu} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className="flex">

      {status === 'not-authenticated' ? (


        <button
          onClick={handleRedirectLogin}
          className={`focus:outline-none flex items-center justify-center ${className}`}
        >


          {showIcon ? (
            <i className="las la-user text-purple-600" style={{ fontSize: '34px' }}></i>
          ) : (
            "Cuenta"
          )}

        </button>
      ) : null}
      <button

        onClick={(e) => {
          handleOpenMenu();
          onClick && onClick(e);
        }}


        className={`focus:outline-none flex items-center justify-center ${className}`}
      >



        {showIcon ? ( // Mostrar icono solo si showIcon es verdadero
          <i className="las la-shopping-bag text-purple-600" style={{ fontSize: '32px' }}></i>
        ) : (
          "Reservar Sala"
          // Mostrar texto "Reservar Sala" si showIcon es falso
        )}

      </button>

      {renderContent()}
    </div>
  );
};

export default MenuBar;
