const sidebarStructure = [
  {
    id: "dashboard",
    title: "Dashboard",
    name: "dashboard",
    parent: false,
    icon: "dasbor",
    link: "dashboard"
  },
  {
    id: "reservations",
    title: "Reservas",
    name: "reservations",
    parent: false,
    icon: "transaksi",
    link: "reservations"
   
  } , 
  {
    id: "users",
    title: "Usuarios",
    name: "users",
    parent: false,
    icon: "transaksi",
    link: "users"
   
  }  
];

export { sidebarStructure };
