import { configureStore } from "@reduxjs/toolkit";
import { uiSlice } from "./ui/uiSlice";
import { reservationsSlice } from "./reservations/reservationsSlice";
import { authSlice } from "./auth/authSlice";
import { slotSlice } from "./slot/slotSlice";
import { usersSlice } from './users/usersSlice';
import { roomSlice } from './room/roomSlice';


export const store = configureStore({
    reducer: {
        ui: uiSlice.reducer,
        reservations: reservationsSlice.reducer,
        auth: authSlice.reducer,
        slot: slotSlice.reducer,
        users: usersSlice.reducer,
        rooms: roomSlice.reducer,
    },
});
