// Desc: Hook para manejar el estado de las reservaciones

import { useDispatch, useSelector } from 'react-redux';
import { onCreateSlot, onLoadSlots, onChecking } from "../store";
import { roomApi } from "../api/";


export const useSlotStore = () => {
    const dispatch = useDispatch();
    const { slots } = useSelector(state => state.slot);



    const createSlot = async ({ start, end, room, price, dayOfWeek }) => {
        dispatch(onChecking());
        try {
            const { data } = await roomApi.post('/slots/create', { start, end, room, price, dayOfWeek });
            dispatch(onCreateSlot({ id: data.id, start: data.start, end: data.end, room: data.room, price: data.price, dayOfWeek: data.dayOfWeek }));
        } catch (e) {


        }

    }



    const getSlots = async ({ dayOfWeek }) => {

        try {
            //console.log("ejecuta getSlots");
            const { data } = await roomApi.post('/slots/getSlots', { dayOfWeek });

            //console.log("data", data);
            dispatch(onLoadSlots(slots));

            dispatch(onLoadSlots(data.slots));

        } catch (e) {

        }

    }




    return {
        //Propiedades
        slots,


        //Metodos
        createSlot,
        getSlots,
    }
}
