
import { useEffect, useState } from "react";

import Sidebar from "shared/Sidebar/Sidebar";
import { useReservationStore } from "hooks";

const fecha = new Date().toLocaleString("en-US", { timeZone: "Europe/Madrid" });
type CountReservation = {
  countMonth: number;
  count: number;
  totalFacturadoHoy: number;
  totalFacturadoMes: number;
};
const PageDashboard = () => {
  const [sideMenuIsExpand, setSideMenuIsExpand] = useState(true);
  // eslint-disable-next-line
  const [fechaSeleccionada, setFechaSeleccionada] = useState<String>(fecha);
  const [countReservation, setCountReservation] = useState<CountReservation>({ countMonth: 0, count: 0, totalFacturadoHoy: 0, totalFacturadoMes: 0 });
  


  const {
    getReservationCountByDate
  } = useReservationStore();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getReservationCountByDate({ date: fechaSeleccionada });
      
      setCountReservation(data);
     
    };
    fetchData();
  }, [fechaSeleccionada]);
  const { countMonth,count,totalFacturadoHoy,totalFacturadoMes } = countReservation;
  return (
   
    

    
    <div>
      <Sidebar setExpand={setSideMenuIsExpand} />
      {/* content */}
      <div
        className={`flex-1 min-h-screen mx-0 bg-slate-100 transition-all duration-300 ease-in-out ${sideMenuIsExpand ? "md:ml-72" : "md:ml-20"
          }`}
      >
        <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
          <h1>Dashboard</h1>
          <div className="grid p-20 grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
            <div className="rounded-lg h-36 min-h-36 border-[1px] border-gray-400 bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="flex h-11.5 w-11.5 items-center justify-start m-2 rounded-full bg-meta-2 dark:bg-meta-4">
                <p>
                  Total reservas hoy
                </p>
              </div>
              <div className="m-5 flex items-center justify-end">
                <p className="text-4xl font-bold">
                  {count}
                  
                </p>
              </div>
            </div>
            <div className="rounded-lg h-36 min-h-36 border-[1px] border-gray-400 bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="flex h-11.5 w-11.5 items-center justify-start m-2 rounded-full bg-meta-2 dark:bg-meta-4">
                <p>
                  Total reservas mes
                </p>
              </div>
              <div className="m-5 flex items-center justify-end">
                <p className="text-4xl text-gray-700 font-bold">
                {countMonth}
                </p>
              </div>
            </div>
            <div className="rounded-lg h-36 min-h-36 border-[1px] border-gray-400 bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="flex h-11.5 w-11.5 items-center justify-start m-2 rounded-full bg-meta-2 dark:bg-meta-4">
                <p>
                  Total facturado hoy
                </p>
              </div>
              <div className="m-5 flex items-center justify-end">
                <p className="text-4xl  font-bold">
                  {totalFacturadoHoy}€
                </p>
              </div>
            </div>
            <div className="rounded-lg border-[1px] border-gray-400 h-36 min-h-36  bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="flex h-11.5 w-11.5 items-center justify-start m-2 rounded-full bg-meta-2 dark:bg-meta-4">
                <p>
                  Total facturado mes
                </p>
              </div>
              <div className="m-5 flex items-center justify-end">
                <p className="text-4xl text-gray-700 font-bold">
                  {totalFacturadoMes}€
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageDashboard;