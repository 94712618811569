import React from "react";
import { ReactNode } from "react";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  heading = "NUESTRAS SALAS",
  subHeading,
}) => {
  return (
    <div className={`mb-10 lg:mb-16 text-center ${className}`}>
      <h2 className="text-5xl sm:text-5xl md:text-7xl lg:text-7xl xl:text-7xl font-extrabold flex justify-center">{heading}</h2>
      {subHeading ? (
        subHeading
      ) : (
        <span className=" text-neutral-500 text-3xl justify-center items-center flex dark:text-white mt-3">
          Abrimos todos los días ⚡
        </span>
      )}
    </div>
  );
};

export default Heading2;
