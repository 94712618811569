
const PoliticasCancelacion = () => {
  return (
    <div>
      <h1 className=" text-5xl text-purple-700 text-center font-bold m-20">
        Politicas De Cancelacion
      </h1>

      <div>
        {" "}
        <div className=" justify-start w-2/3 text-xl m-10">
          <span className=" text-purple-700 text-3xl font-bold">
            Identificación y Titularidad
          </span>
          <br />
          En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de
          Servicios de la Sociedad de la Información y Comercio Electrónico, el
          Titular expone sus datos identificativos:
          <br />
          <span className=" font-bold">Titular:</span> El titular del sitio.
          <br /> <span className=" font-bold">Domicilio:</span> - España.
          <br /> <span className=" font-bold">Sitio Web:</span>
          http://karaokeroomsbarcelona.com
          <br />
          <div className="mt-10">
            {" "}
            <p className=" text-purple-700 text-3xl font-bold ">
              Condiciones de Uso La utilización del Sitio Web{" "}
            </p>
            le otorga la condición de Usuario, e implica la aceptación completa
            de todas las cláusulas y condiciones de uso incluidas en las
            páginas: Aviso Legal Política de Cookies Si no estuviera conforme
            con todas y cada una de estas cláusulas y condiciones absténgase de
            utilizar el Sitio Web. El acceso al Sitio Web no supone, en modo
            alguno, el inicio de una relación comercial con el Titular. A través
            del Sitio Web, el Titular le facilita el acceso y la utilización de
            diversos contenidos que el Titular y/o sus colaboradores han
            publicado por medio de Internet. A tal efecto, está obligado y
            comprometido a NO utilizar cualquiera de los contenidos del Sitio
            Web con fines o efectos ilícitos, prohibidos en este Aviso Legal o
            por la legislación vigente, lesivos de los derechos e intereses de
            terceros, o que de cualquier forma puedan dañar, inutilizar,
            sobrecargar, deteriorar o impedir la normal utilización de los
            contenidos, los equipos informáticos o los documentos, archivos y
            toda clase de contenidos almacenados en cualquier equipo informático
            propios o contratados por el Titular, de otros usuarios o de
            cualquier usuario de Internet.
          </div>
          <div className="mt-10">
            {" "}
            <p className=" text-purple-700 text-3xl font-bold ">
              Medidas de seguridad{" "}
            </p>
            Los datos personales que facilite al Titular pueden ser almacenados
            en bases de datos automatizadas o no, cuya titularidad corresponde
            en exclusiva al Titular, que asume todas las medidas de índole
            técnica, organizativa y de seguridad que garantizan la
            confidencialidad, integridad y calidad de la información contenida
            en las mismas de acuerdo con lo establecido en la normativa vigente
            en protección de datos. No obstante, debe ser consciente de que las
            medidas de seguridad de los sistemas informáticos en Internet no son
            enteramente fiables y que, por tanto el Titular no puede garantizar
            la inexistencia de virus u otros elementos que puedan producir
            alteraciones en los sistemas informáticos (software y hardware) del
            Usuario o en sus documentos electrónicos y ficheros contenidos en
            los mismos aunque el Titular pone todos los medios necesarios y toma
            las medidas de seguridad oportunas para evitar la presencia de estos
            elementos dañinos.
          </div>
          <div className="mt-10">
            {" "}
            <p className=" text-purple-700 text-3xl font-bold ">Contenidos </p>
            El Titular ha obtenido la información, el contenido multimedia y los
            materiales incluidos en el Sitio Web de fuentes que considera
            fiables, pero, si bien ha tomado todas las medidas razonables para
            asegurar que la información contenida es correcta, el Titular no
            garantiza que sea exacta, completa o actualizada. El Titular declina
            expresamente cualquier responsabilidad por error u omisión en la
            información contenida en las páginas del Sitio Web. Queda prohibido
            transmitir o enviar a través del Sitio Web cualquier contenido
            ilegal o ilícito, virus informáticos, o mensajes que, en general,
            afecten o violen derechos del Titular o de terceros. Los contenidos
            del Sitio Web tienen únicamente una finalidad informativa y bajo
            ninguna circunstancia deben usarse ni considerarse como oferta de
            venta, solicitud de una oferta de compra ni recomendación para
            realizar cualquier otra operación, salvo que así se indique
            expresamente. El Titular se reserva el derecho a modificar,
            suspender, cancelar o restringir el contenido del Sitio Web, los
            vínculos o la información obtenida a través del Sitio Web, sin
            necesidad de previo aviso. El Titular no es responsable de los daños
            y perjuicios que pudieran derivarse de la utilización de la
            información del Sitio Web.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoliticasCancelacion;
