import React from "react";

const Proximamente = () => {
  return (
    <div className="flex justify-center items-center mb-[100px] mt-[150px]">
      <span className="text-[40px] sm:text-7xl md:text-8xl lg:text-9xl xl:text-[150px] text-purple-600 font-pump">
        PROXIMAMENTE!!
      </span>
    </div>
  );
};

export default Proximamente;
