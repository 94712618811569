// Desc: Hook para manejar el estado de las reservaciones

import { useDispatch, useSelector } from "react-redux";
import { onLoadUsers, onCheckingUsers, onLoadDeleteUser } from "../store";
import { roomApi } from "../api/";

export const useUsersStore = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);

  const createUser = async ({ name, email, password }) => {
    dispatch(onCheckingUsers());
    try {
      // eslint-disable-next-line
      const { data } = await roomApi.post("/auth/new", {
        name,
        email,
        password,
      });
    } catch (e) {
      console.log(e.response.data);
    }
  };

  const updateUser = async (uid, name, phone, email, address, dateBirth, rol,
  ) => {
    try {
      // eslint-disable-next-line
      const { data } = await roomApi.post("/auth/update", {
        uid,
        name,
        phone,
        email,
        address,
        dateBirth,
        rol,

      });
      getUsers();
    } catch (error) {
      console.error("Error", error);
    }
  };

  const getUsers = async () => {

    try {
      const { data } = await roomApi.post("/auth/users");
      dispatch(onLoadUsers(data.users));
    } catch (e) {
      console.log("Error", e);
    }
  };


  const deleteUser = async (uid) => {
    try {
      // eslint-disable-next-line
      const { id } = await roomApi.delete(`/auth/${uid}`, {
        uid,
      });
      dispatch(onLoadDeleteUser(uid));
    } catch (e) {
      console.log("Error", e);
    }
  };



  return {
    //Propiedades
    users,

    //Metodos
    createUser,
    getUsers,
    updateUser,
    deleteUser,
  };
};
