import { FC } from "react";
import "./sectionhero.scss";
import banner from "images/portadaKaraoke.jpg";
import { useNavigate } from "react-router-dom";



export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  const navigate = useNavigate()

  const handleRedirectReservation = () => {
    navigate("/rooms");
  };

  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
      id="container-hero3"
    >
      <div className="absolute z-10 items-center w-full inset-x-0 top-[50%] sm:top-[50%] text-center flex flex-col" >
        <span className="logoKaraoke object-cover w-full text-5xl sm:text-7xl lg:text-9xl md:text-8xl text-purple-700 font-pump">Karaoke Rooms</span>
      </div>
      <div className="absolute z-10 inset-x-0 top-[65%] sm:top-[70%] text-center flex flex-col items-center w-[300px] sm:w-full md:w-full lg:w-full xl:w-full mx-auto space-y-4 lg:space-y-5 xl:space-y-8">

        <span className="sm:text-lg md:text-2xl font-semibold text-purple-700">
          Tu Sala privada de Karaoke, para todos los públicos.
        </span>
        <div className="icono-flecha">
          <i className="las la-angle-down"></i>
        </div>

      </div>

      <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8">

        <img
          id="banner"
          className="absolute inset-0 object-cover "
          src={banner}
          alt="hero"
        />
      </div>
      <div className="sm:flex hidden">
        <div className="relleno font-bold">
          <div className="marquee">
            <span>🎵 SALAS PRIVADAS  🎤 MICROFONOS INALÁMBRICOS 🎶 +30.000 CANCIONES 👆🏼PANTALLA TÁCTIL&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div className="marquee marquee2">
            <span>🎵 SALAS PRIVADAS  🎶 +30.000 CANCIONES 👆🏼PANTALLA TÁCTIL&nbsp;&nbsp;&nbsp;</span>
          </div>
        </div>
      </div>
      <div className="sm:hidden flex justify-center items-center">
        <button onClick={handleRedirectReservation} className=" mt-10 h-12 w-[90%] flex justify-center items-center rounded-3xl bg-[#8F00FF] uppercase text-center text-2xl font-bold hover:text-slate-100 text-slate-100">
          reservar sala
        </button>
      </div>


    </div>
  );
};

export default SectionHero3;
