import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useForm, useAuthStore } from "hooks";
import Swal from "sweetalert2";


export interface PageForgotPasswordProps {
    className?: string;
}

const loginFormFields = {
    loginEmail: '',
    loginPassword: '',
};

const PageForgotPassword = ({ className }: PageForgotPasswordProps) => {

    const { requestPasswordReser, errorMessage } = useAuthStore();
    const [openModal, setOpenModal] = useState(false);
    const { loginEmail, onInputChange } = useForm(loginFormFields);
    const [hiddenForm, setHiddenForm] = useState(true)


    const handleCloseModal = () => {

        setOpenModal(false)
        setHiddenForm(false);
    };

    useEffect(() => {
        if (errorMessage !== undefined) {
            Swal.fire('Error en la autenticación', errorMessage, 'error');
        }
    }, [errorMessage])


    const handleResetPasswd = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            requestPasswordReser({ email: loginEmail });
            setOpenModal(true)

        } catch (error) {
            console.log('Error en el login', error);
        }
    };


    return (
        <>

            <div className={`nc-PageForgotPassword ${className}`} data-nc-id="PageForgotPassword">
                <Helmet>
                    <title>Login || Karaoke Room Barcelona</title>
                </Helmet>
                <div className="container mb-24 lg:mb-32">

                    <h2 className="my-20 flex items-center text-2xl leading-[105%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        ¿No recuerdas tu contraseña?
                    </h2>

                    <div className="max-w-md mx-auto space-y-6">

                        {/* FORM */}

                        ${hiddenForm ? (
                            <form className={`grid grid-cols-1 gap-6 `} onSubmit={handleResetPasswd}>
                                <label className="block">
                                    <span className="text-neutral-800 dark:text-neutral-200">
                                        Correo Electrónico
                                    </span>
                                    <Input
                                        type="email"
                                        placeholder="example@example.com"
                                        className="mt-1"
                                        name="loginEmail"
                                        value={loginEmail}
                                        onChange={onInputChange}
                                        required

                                    />
                                </label>
                                <ButtonPrimary type="submit">Enviar</ButtonPrimary>
                            </form>
                        ) : (
                            <div className=" justify-center items-center flex">
                                <p className="text-white text-2xl hover:text-gray-400">
                                    <Link to={"/login"}>ir a login</Link>
                                </p>
                            </div>

                        )}


                        {openModal && (
                            <div>
                                <div className="fixed z-10 inset-0 overflow-y-auto">
                                    <div className="flex items-center justify-center h-[90%] ">
                                        <div className="bg-black border-2 flex flex-col justify-center border-white rounded-lg w-[350px] h-[250px] shadow-lg p-4 modal-content">


                                            <p className=" text-center text-white text-lg">Si su email está registrado, debería recibir un email con los pasos a seguir.</p>

                                            <div className="mt-5 flex justify-center items-center">

                                                <button
                                                    className="bg-purple-600 my-2 hover:bg-purple-700 flex items-center justify-center text-white hover:text-gray-300 font-bold py-2 px-4 rounded"
                                                    onClick={() => {
                                                        handleCloseModal();

                                                    }}
                                                >
                                                    Cerrar
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}

export default PageForgotPassword