import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, redirect } from "react-router-dom";
import { useForm, useAuthStore } from "hooks";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


import Swal from "sweetalert2";


export interface PageSignUpProps {
  className?: string;
}
// export interface userRegister {
//   registerName: string;
//   registerEmail: string;
//   registerPassword: string;
//   registerPassword2: string;
// }

// eslint-disable-next-line
const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const registerFormFields = {
  registerName: '',
  registerEmail: '',
  registerPhone: '',
  registerAddress: '',
  registerDateBirth: '',
  registerPassword: '',
  registerPassword2: '',
};


const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { registerName, registerEmail, registerPhone, registerPassword, registerPassword2, onInputChange } = useForm(registerFormFields);
  const { startRegister, errorMessage } = useAuthStore();
  const [error, setError] = useState("")

  //Envio del formulario
  const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (!registerName && !registerEmail && !registerPhone) {
        setError("Todos los campos marcados con (*) son obligatorios");
        return;
      }
      if (!registerName) {
        setError("Debes completar el campo nombre")
        return;
      }
      if (!registerEmail) {
        setError("Debes completar el campo Email")
        return;
      }
      if (!registerPhone) {
        setError("Debes completar el campo Movil")
        return;
      }

      if (registerPassword !== registerPassword2) {
        //Swal.fire('Error en registro', 'Las contraseñas no coinciden', 'error');
        setError("Las contraseñas no coinciden")
        return;
      }

      const data = startRegister({ name: registerName, email: registerEmail, phone: registerPhone, password: registerPassword });
      console.log("datos:", data)
      //setError(data.error)


    } catch (error) {
       setError("Error en el registro")
      // Manejar el error, por ejemplo, mostrar un mensaje de error al usuario
      // console.error('Error en el login:', error.message);
    }
  };
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Darme de alta || Karaoke Room Barcelona</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Darme De Alta
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">

          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleRegister}>
            <label className="block">
              <span className=" dark:text-neutral-200">
                Nombre <span className="ml-2 text-red-600">*</span>
              </span>
              <Input
                type="Text"
                className="mt-1"
                placeholder="Nombre"
                name="registerName"
                value={registerName}
                onChange={onInputChange}

              />
            </label>
            <label className="block">
              <span className="flex items-center  dark:text-neutral-200">
                Email <span className="ml-2 text-red-600">*</span>
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                name="registerEmail"
                value={registerEmail}
                onChange={onInputChange}
              />
            </label>
            <label className="block">
              <span className="flex items-center  dark:text-neutral-200">
                Movil <span className="ml-2 text-red-600">*</span>
              </span>

              {/**
              * 
              * <Input
                type="tel"
                className="mt-1"
                name="registerPhone"
                value={registerPhone}
                onChange={onInputChange}
              />
              */}

              <PhoneInput
                country={'es'}
                placeholder="Ingrese su número"
                inputStyle={{
                  color: "#e5e7eb",         // Color del texto
                  background: "transparent", // Fondo transparente
                  borderRadius: "10px",      // Borde redondeado del contenedor
                  border: "1px solid #2c3e50",
                  width: "100%"
                  
                }}

                buttonStyle={{
                  background: "transparent", 
                  border: "none"  
                  
                }}
                
              
                

                onChange={(value) => onInputChange({ target: { name: 'registerPhone', value } })}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center  dark:text-neutral-200">
                Contraseña
              </span>
              <Input
                type="password"
                placeholder="*********"
                className="mt-1"
                name="registerPassword"
                value={registerPassword}
                onChange={onInputChange}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center  dark:text-neutral-200">
                Repetir Contraseña
              </span>
              <Input
                type="password"
                placeholder="*********"
                className="mt-1"
                name="registerPassword2"
                value={registerPassword2}
                onChange={onInputChange}
              />
            </label>

            {error && (
              <p className=" text-red-500 text-center">{error}</p>

            )}


            <ButtonPrimary type="submit">Registrarme</ButtonPrimary>

          </form>

          {/* ==== */}
          <span className="block text-center  dark:text-neutral-300">
            ¿Ya tienes una cuenta?  {` `}
            <Link to="/login">Acceder</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
