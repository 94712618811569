import { createSlice } from "@reduxjs/toolkit";

export const reservationsSlice = createSlice({
  name: "reservations",
  initialState: {
    reservations: [],
    isLoadingReservations: true,

    salas: [
      {
        id: "666b16f99e6ba415c6af2c7c",
        capacidad: "16",
        capacidadMin: "8",
        name: "Sala 1",
        services: [{
          a: "35m2 climatizados",
          b: "Pantalla Karaoke táctil",
          c: "4 micros inalámbricos",
          d: 'Pantalla 150"',
          e: "+30000 canciones"
        }]
      },
      {
        id: "666b16f99e6ba415c6af2c7d",
        capacidad: "12",
        capacidadMin: "6",
        name: "Sala 2",
        services: [{
          a: "24m2 climatizada",
          b: "Pantalla Karaoke táctil",
          c: "4 micros inalámbricos",
          d: "+30000 canciones",
          e: null,
        }]
      },
      {
        id: "666b16f99e6ba415c6af2c7e",
        capacidad: "8",
        capacidadMin: "4",
        name: "Sala 3",
        services: [{
          a: "24m2 climatizada",
          b: "Pantalla Karaoke táctil",
          c: "4 micros inalámbricos",
          d: "+30000 canciones"  
        }]
      },
      {
        id: "666b16f99e6ba415c6af2c7f",
        capacidad: "8",
        capacidadMin: "4",
        name: "Sala 4",
        services: [{
          a: "20m² climatizados.",
          b: "Pantalla Karaoke táctil",
          c: "4 micros inalámbricos",
          d: 'Pantalla 65"',
          e:"+30000 canciones"
        }]
      },
      {
        id: "666b16f99e6ba415c6af2c80",
        capacidad: "8",
        capacidadMin: "4",
        name: "Sala 5",
        services: [{
          a: "20m2 climatizados.",
          b: "Pantalla Karaoke táctil",
          c: "4 micros inalámbricos",
          d: 'Pantalla 75"',
          e:"+30000 canciones"
        }]
      },
      {
        id: "666b16f99e6ba415c6af2c81",
        capacidad: "12",
        capacidadMin: "6",
        name: "Sala 6",
        services: [{
          a: "26m2 climatizados.",
          b: "Pantalla Karaoke táctil",
          c: "4 micros inalámbricos",
          d: 'Pantalla 75"',
          e: '+30000 canciones',
        }]
      },
      {
        id: "666b16f99e6ba415c6af2c82",
        capacidad: "30",
        capacidadMin: "15",
        name: "Sala Suite",
        services: [{
          a: "70m2 climatizados.",
          b: "Pantalla Karaoke táctil",
          c: "4 micros inalámbricos",
          d: 'Pantalla 150"',
          e: 'Pantalla Auxiliar 40"',
          f: 'Vistas Panoramicas',
          g: '+30000 canciones',
        }]
      },
    ],
    car: JSON.parse(localStorage.getItem("car")) || [],
  },

  reducers: {
    addElementCarReducer: (state, action) => {


      //Por Id:

      const { id } = action.payload;
      const existingItem = state.car.find((item) => item.id === id);
      if (!existingItem) {
        state.car.push(action.payload);
        localStorage.setItem("car", JSON.stringify(state.car));
      } else {
        console.log("El elemento ya está en el carrito");
      }
    },

    deleteElementCarReducer: (state, action) => {
      state.car = state.car.filter((item) => item.id !== action.payload);
      localStorage.setItem("car", JSON.stringify(state.car));
    },
    onLoadReservations: (state, { payload = [] }) => {
      state.isLoadingReservations = false;
      state.reservations = payload;
    }
    ,

    onAddReservationCar: (state, { payload }) => {
      
      state.car = payload;
    },
    onCleanReservationCar: (state) => {
      state.car = {};
      localStorage.setItem("car", {});
    },
  },
});

export const {
  addElementCarReducer,
  deleteElementCarReducer,
  onAddReservationCar,
  onLoadReservations,
  onCleanReservationCar,
} = reservationsSlice.actions;
