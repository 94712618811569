import logoImg from "images/logo.png";


const CartaBebidas = () => {
    return (
        <>
            <div className="flex flex-col justify-center p-5 items-center">
                <img src={logoImg} alt="Karaoke Rooms" className="w-[450px] mt-10" />

                <div className="border-white justify-center items-center flex border-[10px] mt-24 p-5 w-full xl:w-1/2 rounded-lg">
                    <table className=" text-white  ">
                        <thead >
                            <tr>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="text-lg md:text-2xl">
                            <tr className=" uppercase ">
                                <td className="py-5">agua 50cl</td>
                                <td className="py-5">1,00€</td>
                            </tr>
                            <tr className=" uppercase ">
                                <td className="py-5">refrescos <span className=" inline-block text-xs">(cocacola/zero, fanta/scheweppes naranja o limón, aquiarius, nestea, sevenup, tónica)</span></td>
                                <td>2,00€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">zumo de piña/naranja</td>
                                <td>2,50€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">guarana</td>
                                <td>2,50€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">vichy catalán</td>
                                <td>2,50€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">cervreza estrella de galicia</td>
                                <td>2,50€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">cerveza sin gluten</td>
                                <td>2,50€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">cerveza sin alcohol</td>
                                <td>2,50€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">cerveza coronita</td>
                                <td>3,00€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">redbull</td>
                                <td>3,00€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">licor solo 50ml <span className=" inline-block text-xs">(ron, vodka, malibu, puerto de indias, ginebra, licor 43, whisky, jager)</span></td>
                                <td>3,50€</td>
                            </tr>

                            <tr className=" uppercase">
                                <td className="py-5">combinado con refresco</td>
                                <td>5,50€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">combinado + zumo</td>
                                <td>6,00€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">combinado + redbull</td>
                                <td>6,50€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">vino blanco marqués de riscal 75cl</td>
                                <td>16,00€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">vino tinto sisquera 75cl</td>
                                <td>16,00€</td>
                            </tr>
                            <tr className=" uppercase">
                                <td className="py-5">botella de cava</td>
                                <td>25,00€</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <h2 className=" text-2xl md:text-4xl text-center uppercase my-10">!pide desde tu movil en la sala y te lo llevamos¡</h2>

                {/*                <div className="grid grid-cols-2 mt-10 w-1/2 justify-items-center ">
                    <div className=" col-span-1  text-white text-2xl uppercase ">
                        <p className="my-4">
                            agua 50cl
                        </p>
                        <p className="my-4">
                            refrescos <span className=" inline-block text-xs">(cocacola/zero, fanta/scheweppes naranja o limón, aquiarius, nestea, sevenup, tónica)</span>
                        </p>
                        <p className="my-4">
                            zumo de piña/naranja
                        </p>
                    </div>
                    <div className=" col-span-1 text-white text-2xl  uppercase">
                        <p className="my-4">
                            1,00€
                        </p>
                        <p className="my-4">
                            2,00€
                        </p>
                        <p className="my-4">
                            2,50€
                        </p>

                    </div>

                </div>*/}

            </div>
        </>
    )
}

export default CartaBebidas