import CommonLayout from 'containers/AccountPage/CommonLayout'
import React from 'react'
import sala1 from '../../images/sala1.png'
import sala2 from '../../images/sala2.png'
import sala3 from '../../images/sala4.png'
import { useAuthStore } from 'hooks'
import { useReservationStore } from 'hooks'
import { useState, useEffect } from 'react'
import "./myreservations.scss"


// Definición de los servicios dentro de la sala
interface Service {
    a: string;
    b: string;
    c: string;
    d: string;
    e?: string | null; // Opcional porque puede ser null
}

// Definición de la sala
interface Room {
    _id: string;
    capacidad: string;
    capacidadMin: string;
    services: Service[];
    images: Array<{ url: string }>;
    name: string;
    order: number; // Número
}

// Definición de la reserva
interface Reservation {
    start: string;
    end: string;
    date: string;
    npersons: number; // Número
    user: string;
    slot: string;
    price: string;
    typereservation: string;
    statuspayment: string;
    room: Room;
    totalPayed: string;
    createdAt: string;
    updatedAt: string;
    id: string;
}

// Definición de la respuesta completa
interface ReservationResponse {
    reservations: Reservation[];
}




const MyReservation = () => {
    const { user } = useAuthStore();
    const { getReservationByUser } = useReservationStore();
    const [reservations, setReservations] = useState<Reservation[]>([]);
    const [dataReservationQuantity, setDataReservationQuantity] = useState(20)
    const [currentPage, setCurrentPage] = useState(1)

    //Paginacion:

    let totalReservations = reservations.length; //indice inicial
    const totalPages = Math.ceil(totalReservations / dataReservationQuantity);


    // Función para obtener las reservas de la página actual
    const getCurrentReservations = () => {
        const startIndex = (currentPage - 1) * dataReservationQuantity;
        const endIndex = startIndex + dataReservationQuantity;
        return reservations.slice(startIndex, endIndex);
    };

    const nextPage = () => {
        if (currentPage !== totalPages) {
            setCurrentPage(currentPage + 1)
        }
    }

    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const getPageNumbers = () => {
        const pageNumbers = [];
        if (totalPages <= 3) {
            // Si hay menos de 6 páginas, mostramos todas
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            if (currentPage <= 3) {
                // Mostrar las primeras 3 páginas y los puntos
                pageNumbers.push(1, 2, 3, '...', totalPages - 2, totalPages - 1, totalPages);
            } else if (currentPage >= totalPages - 2) {
                // Mostrar las últimas 3 páginas y los puntos
                pageNumbers.push(1, 2, 3, '...', totalPages - 2, totalPages - 1, totalPages);
            } else {
                // Mostrar páginas alrededor de la página actual
                pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
            }
        }
        return pageNumbers;
    };



    useEffect(() => {
        const fetchReservations = async () => {
            try {
                const data = await getReservationByUser(user.uid);
                setReservations(data);
            } catch (error) {
                console.error("Error al obtener reservas:", error);
            }
        };
        fetchReservations()
    }, [user]);

    const formatDate = (isoDate: string) => {
        const date = new Date(isoDate);
        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };




    return (
        <>
            <CommonLayout>
                <h1 className='my-0 mb-12 sm:my-12 text-white uppercase text-3xl'> mis reservas</h1>

                {reservations.length > 0 ? (
                    <>
                        <div className="table-container relative overflow-auto flex flex-col w-full min-h-[400px]  text-slate-400 bg-transparent shadow-md border-slate-600 border-[1px] rounded-t-xl ">
                            <table className="  text-left w-full ">
                                <thead >
                                    <tr className="border-b rounded-lg border-slate-600 bg-transparent">
                                        <th className="p-4 text-sm font-bold uppercase leading-none text-slate-100 "></th>
                                        <th className="p-4 text-sm font-bold uppercase leading-none text-slate-100 ">SALA</th>
                                        <th className="p-4 text-sm font-bold uppercase leading-none text-slate-100 ">FECHA DE RESERVA</th>
                                        <th className="p-4 text-sm font-bold uppercase leading-none text-slate-100 ">HORA INICIO</th>
                                        <th className="p-4 text-sm font-bold uppercase leading-none text-slate-100 ">HORA FIN</th>
                                        <th className="p-4 text-sm font-bold uppercase leading-none text-slate-100 ">reservado para</th>
                                        <th className="p-4 text-sm font-bold uppercase leading-none text-slate-100 ">CAPACIDAD MAX SALA</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {getCurrentReservations().map((reservation: Reservation) => (
                                        <tr key={reservation.id} className="hover:bg-purple-950">
                                            <td className="p-4 border-b  border-slate-600 py-1">
                                                <img src={reservation.room.images[0].url} alt="imagen de la sala" className="block w-full h-8 object-contain " style={{ minWidth: '80px', minHeight: '80px' }} />
                                            </td>
                                            <td className="p-4 border-b border-slate-600 py-1">
                                                <p className="block text-sm text-slate-400">{reservation.room.name}</p>
                                            </td>
                                            <td className="p-4 border-b border-slate-600 py-1">
                                                <p className="text-sm text-slate-400">{formatDate(reservation.date)}</p>
                                            </td>
                                            <td className="p-4 border-b border-slate-600 py-1">
                                                <p className="text-sm text-slate-400">{reservation.start}</p>
                                            </td>
                                            <td className="p-4 border-b border-slate-600 py-1">
                                                <p className="text-sm text-slate-400">{reservation.end}</p>
                                            </td>
                                            <td className="p-4 border-b border-slate-600 py-1">
                                                <p className="text-sm text-slate-400">{reservation.room.capacidadMin} - personas</p>
                                            </td>
                                            <td className="p-4 border-b border-slate-600 py-1">
                                                <p className="text-sm text-slate-400">{reservation.room.capacidad}</p>
                                            </td>
                                        </tr>

                                    ))}


                                </tbody>
                            </table>

                        </div>

                        <div className="flex text-white flex-col justify-center items-center p-6">
                            <span className="font-bold text-sm uppercase ">Pagina: {currentPage} / {totalPages}</span>
                            <nav aria-label="Pagination" className="w-full bg-transparent m-3 flex flex-1 items-center justify-between rounded-md ">

                                <div>
                                    <button
                                        onClick={prevPage}
                                        disabled={currentPage === 1}
                                        className="relative text-white hover:text-purple-700 justify-center w-20 inline-flex items-center rounded-lg px-2 py-2 font-bold border-[1px] border-white focus:z-20 focus:outline-offset-0"
                                    >
                                        <span className="font-bold">Antes</span>

                                    </button>
                                </div>

                                <div>
                                    {getPageNumbers().map((page, index) => (
                                        typeof page === 'number' ? (
                                            <button
                                                key={index}
                                                onClick={() => setCurrentPage(page)}
                                                className={`relative hover:text-purple-700 z-10 mx-1 inline-flex items-center px-4 py-2 text-sm font-semibold ${currentPage === page ? "border-[1px] border-white rounded-lg" : ""}`}
                                            >
                                                {page}
                                            </button>
                                        ) : (
                                            <span key={index} className="mx-1 px-3 py-1">...</span>
                                        )
                                    ))}
                                </div>


                                <div>
                                    <button
                                        onClick={nextPage}
                                        disabled={currentPage === totalPages}
                                        className="relative hover:text-purple-700 text-white justify-center w-20 inline-flex items-center rounded-lg px-2 py-2 font-bold border-[1px] border-white focus:z-20 focus:outline-offset-0"
                                    >
                                        <span className="">Siguiente</span>
                                    </button>
                                </div>

                            </nav>

                        </div>
                    </>

                ) : (
                <p className='text-purple-700 text-2xl text-center uppercase font-bold'>aun no hay reservas</p>
                )}




            </CommonLayout>
        </>
    )
}

export default MyReservation;