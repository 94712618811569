const PagePoliticasPrivacidad = () => {
  return (
    <div className="text-[#6b7280]">
      <h1 className=" text-5xl uppercase text-purple-700 text-center font-bold mt-10 md:m-20">
        Politica De Privacidad
      </h1>

      <div>
        {" "}
        <div className=" justify-center md:justify-start md:w-2/3 text-xl m-10">
          <br />

          <p>
            La presente Política de Privacidad ha sido desarrollada teniendo en cuenta lo dispuesto por
            la Ley Orgánica de Protección de Datos de carácter personal en vigor, así como por el Reglamento
            2016/679 del Parlamento Europeo y del consejo del 27 de abril de 2016 relativo a la protección
            de las personas físicas en lo que respecta al tratamiento de datos personales y a la circulación
            de estos datos, en adelante el RGPD.

          </p><br />

          <p>
            Esta Política de Privacidad tiene por objeto poner en conocimiento de los titulares de los datos personales,
            respecto de los cuales se está recabando información, los aspectos específicos relativos al tratamiento sus
            datos, entre otras cosas, las finalidades de los tratamientos, los datos de contacto para ejercer los derechos
            que le asisten, los plazos de conservación de la información y las medidas de seguridad entre otras cosas.


          </p><br />

          <div className="mt-10">
            {" "}
            <p className=" text-purple-700 uppercase text-3xl font-bold ">
              Responsable del Tratamiento
            </p><br />

            <p>
              <span className=" font-bold underline">Denominación:</span> PRESENTA PRODUCCIONES, S.L
            </p><br />

            <p>
              <span className=" font-bold underline">Dirección:</span> Calle can Travi, 32 - P. 4 PTA. 4, Barcelona, 08035.
            </p><br />

            <p>
              <span className=" font-bold underline">NIF:</span> B64460066
            </p><br />

            <p>
              <span className=" font-bold underline">Teléfono de contacto:</span> 675 92 38 26
            </p><br />

            <p>
              <span className=" font-bold underline">Correo electrónico:</span> infol@karaokeroomsbarcelona.com
            </p><br />

          </div>
          <div className="mt-10">
            {" "}
            <p className=" text-purple-700 text-3xl font-bold ">
              Tratamientos de datos:
            </p><br />
            <p>
              Los datos de carácter personal que se soliciten, en su caso, consistirán únicamente en aquellos
              estrictamente imprescindibles para identificar y atender la solicitud realizada por el titular
              de los mismos, en adelante el interesado. Dicha información será tratada de forma leal, lícita
              y transparente en relación con el interesado. Por otra parte, los datos personales serán recogidos
              para finalidades determinadas explícitas y legítimas, no siendo tratados ulteriormente de manera
              incompatible con dichos fines.
            </p>

            <p>
              Los datos recogidos de cada interesado serán adecuados, pertinentes y no excesivos en relación a las
              finalidades correspondientes para cada caso, y serán actualizados siempre que sea necesario.
            </p>

            <p>
              El titular de los datos será informado, con carácter previo a la recogida de sus datos, de los extremos
              generales regulados en esta política a fin de que pueda prestar el consentimiento expreso, preciso e inequívoco
              para el tratamiento de sus datos, conforme a los siguientes aspectos.
            </p><br /><br />

            <p>
              <section className=" underline text-2xl text-purple-600">Finalidades del tratamiento.</section>

              Las finalidades explícitas para las cuales se llevan a cabo cada uno de los tratamientos vienen recogido en las cláusulas informativas incorporadas en cada una de las vías de toma de datos (formularios web, newsletter…). <br /><br />
              No obstante, los datos de carácter personal del interesado serán tratados con la exclusiva finalidad de proporcionarles una respuesta efectiva y atender las solicitudes practicadas por el usuario, especificadas junto a la opción, servicio, formulario o sistema de toma de datos que el titular utilice.

            </p><br />

            <p>
              <section className=" underline text-2xl text-purple-600">Legitimación</section>
              Por regla general, previo al tratamiento de los datos personales, <span className=" font-bold">PRESENTA PRODUCCIONES, S.L</span>  obtiene consentimiento expreso e inequívoco del titular de los mismos, mediante la incorporación de cláusulas de consentimiento informado en los diferentes sistemas de recogida de información. <br /><br />
              No obstante, en caso de que no se requiera el consentimiento del interesado, la base legitimadora del tratamiento en la cual se ampara <span className=" font-bold">PRESENTA PRODUCCIONES, S.L</span>  es la existencia de una ley o norma específica que autorice o exija el tratamiento el tratamiento de los datos del interesado.

            </p><br />

            <p>
              <section className=" underline text-2xl text-purple-600">Destinatarios</section>
              Por regla general, <span className=" font-bold">PRESENTA PRODUCCIONES, S.L</span>  no procede a la cesión o comunicación de los datos a terceras entidades, salvo las requeridas legalmente, no obstante, en caso de que fuera necesario, dichas cesiones o comunicaciones de datos se informan al interesado a través de las cláusulas de consentimiento informado contenidas en las diferentes vías de recogida de datos personales.

            </p><br />

            <p>
              <section className=" underline text-2xl text-purple-600">Procedencia</section>

              Por regla general, los datos personales se recogen siempre directamente del interesado, no obstante, en determinadas excepciones, los datos pueden ser recogidos a través de terceras personas, entidades o servicios diferentes del interesado. En este sentido, este extremo será trasladado al interesado a través de las cláusulas de consentimiento informado contenidas en las diferentes vías de recogida de información y dentro de un plazo razonable, una vez obtenidos los datos, y a más tardar dentro de un mes.
            </p><br />

            <p>
              <section className=" underline text-2xl text-purple-600">Plazos de conservación</section>
              La información recabada del interesado será conservada mientras sea necesaria para cumplir con la finalidad para la cual fueron recabados los datos personales, de forma que, una vez cumplida la finalidad los datos serán cancelados. Dicha cancelación dará lugar al bloqueo de los datos conservándose únicamente a disposición de las AAPP, Jueces y Tribunales, para atender las posibles responsabilidades nacidas del tratamiento, durante
              el plazo de prescripción de éstas, cumplido el citado plazo se procederá a la destrucción de la información.
            </p><br />

            <p>
              <section className=" underline text-2xl text-purple-600">Datos de navegación:</section>
              En relación a los datos de navegación que se puedan tratar a través del sitio web, en caso de que se recojan datos sometidos a la normativa, se recomienda consultar la Política de Cookies publicada en nuestro sitio web.
            </p><br />

            <p>
              <section className=" underline text-2xl text-purple-600">Derechos de los interesados:</section>

              La normativa en materia de protección de datos otorga una serie de derechos a los interesados o titulares de los datos, usuarios del sitio web o usuarios de los perfiles de las redes sociales de <span className=" font-bold">PRESENTA PRODUCCIONES, S.L</span><br /><br />
              Estos derechos que asisten a las personas interesadas son los siguientes: <br /><br />
              ➡️<span className=" underline">Derecho de acceso:</span> derecho a obtener información sobre si sus propios datos están siendo objeto de tratamiento, la finalidad del tratamiento que se esté realizando, las categorías de datos que se trate, los destinatarios o categorías de destinatarios, el plazo de conservación y el origen de dichos datos.
              <br />➡️<span className=" underline">Derecho de rectificación:</span> derecho a obtener la rectificación de los datos personales inexactos o incompletos.
              <br />➡️<span className=" underline">Derecho de supresión:</span> derecho a obtener la supresión de los datos personales.
              <br />➡️<span className=" underline">Derecho de oposición:</span> derecho a oponerse a un determinado tratamiento basado en el consentimiento del interesado.
              <br />➡️<span className=" underline">Derecho de limitación:</span> derecho a obtener la limitación del tratamiento de los datos.
              <br />➡️<span className=" underline">Derecho a la portabilidad:</span> derecho a obtener los datos en un formato estructurado, de uso común y de lectura mecánica, y a transmitirlos a otro responsable del tratamiento conforme a la normativa de protección de datos. <br /><br />
              Asimismo, el interesado tiene derecho a presentar una reclamación ante la autoridad de control competente <br /><br />
              Los interesados podrán ejercitar los derechos indicados, dirigiéndose a <span className=" font-bold">info@karaokeroomsbarcelona.com</span> mediante escrito, remitido a la siguiente dirección: <span className=" font-bold">Av. del Carrilet, 237, 08907 L’Hospitalet de Llobregat</span>, Barcelona indicando en la línea de Asunto el derecho que desea ejercitar y adjuntando documento acreditativo de la identidad (pe DNI) <br /><br />
              En este sentido <span className=" font-bold">PRESENTA PRODUCCIONES, S.L</span> atenderá su solicitud a la mayor brevedad posible y teniendo en cuenta los plazos previstos en la normativa en materia de protección de datos.

            </p><br />

            <p className=" font-bold text-center">
              En L’Hospitalet de Llobregat, a 21 de mayo del 2024
            </p>


          </div>

        </div>
      </div>
    </div>
  );
};

export default PagePoliticasPrivacidad;
