import React, { FC, useEffect } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useForm, useAuthStore } from "hooks";
import Swal from "sweetalert2";



export interface PageLoginProps {
  className?: string;
}

// eslint-disable-next-line
const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];
const loginFormFields = {
  loginEmail: '',
  loginPassword: '',
};

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const { startLogin, errorMessage } = useAuthStore();

  const { loginEmail, loginPassword, onInputChange } = useForm(loginFormFields);

  useEffect(() => {
    if (errorMessage !== undefined) {
      Swal.fire('Error en la autenticación', errorMessage, 'error');
    }
  }, [errorMessage])

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      startLogin({ email: loginEmail, password: loginPassword });

    } catch (error) {
      console.log('Error en el login', error);
    }
  };



  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Karaoke Room Barcelona</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-xl leading-[105%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Correo Electrónico
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                name="loginEmail"
                value={loginEmail}
                onChange={onInputChange}

              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Contraseña
                <Link to="/forgot-password" className="text-sm">
                  ¿Has olvidado tu contraseña?
                </Link>
              </span>
              <Input type="password"
                className="mt-1"
                name="loginPassword"
                value={loginPassword}
                onChange={onInputChange}
              />
            </label>
            <ButtonPrimary type="submit">Acceder</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            ¿ Nuevo usuario ? {` `}
            <Link to="/signup">Crear una cuenta</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
