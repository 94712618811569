import { FC } from "react";

import polaroid from "images/polaroidsection.png";

export interface SectionPolaroidProps {
    className?: string;
}

const SectionPolaroid: FC<SectionPolaroidProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-SectionHero3 relative ${className}`}
            data-nc-id="SectionHero3"
            id="container-hero3"
        >

            <div className="absolute w-1/2 z-10 ml-10 mt-20 flex flex-col">
                <h2 className="text-left font-bold text-5xl sm:text-5xl md:text-5xl lg:text-8xl xl:text-8xl">
                    RESERVA TU POLAROID
                </h2>
                <span className="w-80 text-xl">Déjate llevar por el mood y llevate inolvidables fotos instantaneas.</span>
            </div>


            <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-12 lg:aspect-h-8 xl:aspect-h-4">

                <img
                    id="banner"
                    className="inset-0 object-cover rounded-3xl "
                    src={polaroid}
                    alt="hero"
                />
            </div>
        </div>
    );
};

export default SectionPolaroid;
