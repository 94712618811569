import { ComponentType, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageRoomCalendar from "containers/PageRoomCalendar/PageRoomCalendar";
import SiteHeader from "containers/SiteHeader";
import useWindowSize from "hooks/useWindowResize";
import PoliticasCancelacion from "containers/PagePoliticasCancelacion/PoliticasCancelacion";
import PagePoliticasCookies from "containers/PagePoliticasCookies/PagePoliticasCookies";
import PagePoliticasPrivacidad from "containers/PagePoliticasPrivacidad/PagePoliticasPrivacidad";
import PayError from "containers/PayPage/PayError";
import ComingSoonPage from "containers/ComingSoonPage/ComingSoonPage";
import { useAuthStore } from 'hooks';
import PageReservations from "containers/PageAdminReservations/PageReservations";
import PageDashboard from "containers/PageAdminDashboard/PageDashboard";
import PageUsers from "containers/PageAdminUsers/PageUsers";
import PageResetPassword from "containers/PageResetPassword/PageResetPassword";
import PageForgotPassword from "containers/PageForgotPassword/PageForgotPassword";
import CartaBebidas from "components/CartaBebidas/CartaBebidas";
import FooterNav from "components/FooterNav";
import Footer from "shared/Footer/Footer";
import MyReservation from "containers/MyReservationPage/MyReservation";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome, private: false },
  { path: "/#", exact: true, component: PageHome, private: false },
  { path: "/home-1-header-2", exact: true, component: PageHome, private: false },
  // { path: "/home-2", component: PageHome2 },
  // { path: "/home-3", component: PageHome3 },
  //
  // { path: "/listing-stay", component: ListingStayPage },
  // { path: "/listing-stay-map", component: ListingStayMapPage },
  // { path: "/listing-stay-detail", component: ListingStayDetailPage },
  //
  // {
  //   path: "/listing-experiences",
  //   component: ListingExperiencesPage,
  // },
  // {
  //   path: "/listing-experiences-map",
  //   component: ListingExperiencesMapPage,
  // },
  // {
  //   path: "/listing-experiences-detail",
  //   component: ListingExperiencesDetailPage,
  // },
  //
  // { path: "/listing-car", component: ListingCarPage },
  // { path: "/listing-car-map", component: ListingCarMapPage },
  // { path: "/listing-car-detail", component: ListingCarDetailPage },
  //
  // { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  // { path: "/listing-real-estate", component: ListingRealEstatePage },
  //
  // { path: "/listing-flights", component: ListingFlightsPage },
  // //
  { path: "/checkout", component: CheckOutPage, private: false },
  { path: "/pay-done", component: PayPage, private: false },
  { path: "/pay-error", component: PayError, private: false },
  { path: "/reset-password", component: PageResetPassword, private: false },
  { path: "forgot-password", component: PageForgotPassword, private: false },


  //
  // { path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage, private: true },
  { path: "/account-password", component: AccountPass, private: true },
  { path: "/account-savelists", component: AccountSavelists, private: true },
  { path: "/account-billing", component: AccountBilling, private: true },
  { path: "/my-reservations", component: MyReservation, private: true },
  //
  // { path: "/blog", component: BlogPage },
  // { path: "/blog-single", component: BlogSingle },
  //
  // { path: "/add-listing-1", component: PageAddListing1 },
  // { path: "/add-listing-2", component: PageAddListing2 },
  // { path: "/add-listing-3", component: PageAddListing3 },
  // { path: "/add-listing-4", component: PageAddListing4 },
  // { path: "/add-listing-5", component: PageAddListing5 },
  // { path: "/add-listing-6", component: PageAddListing6 },
  // { path: "/add-listing-7", component: PageAddListing7 },
  // { path: "/add-listing-8", component: PageAddListing8 },
  // { path: "/add-listing-9", component: PageAddListing9 },
  // { path: "/add-listing-10", component: PageAddListing10 },
  //
  { path: "/contact", component: PageContact, private: false },
  // { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp, private: false },
  { path: "/login", component: PageLogin, private: false },
  { path: "/politicas-cancelacion", component: PoliticasCancelacion, private: false },
  { path: "/politicas-cookies", component: PagePoliticasCookies, private: false },
  { path: "/politicas-privacidad", component: PagePoliticasPrivacidad, private: false },
  { path: "/comingsoon", component: ComingSoonPage, private: false },
  { path: "/carta-bebidas", component: CartaBebidas, private: false },
  



  { path: "/rooms", component: PageRoomCalendar as ComponentType<any>, private: false },



  // { path: "/subscription", component: PageSubcription },
  //
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    // eslint-disable-next-line
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }
  //type AuthStatus = 'authenticated' | 'no-authenticated' | 'unknown';

  // eslint-disable-next-line
  const { status, user, checkAuthToken, startLogout } = useAuthStore();
  useEffect(() => {
    checkAuthToken();
  }
    , []);

  if (status === 'checking') {
    return <div>Cargando...</div>
  }



  return (

    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        {
          status === 'authenticated' && user.rol === 'user' ?
            <>
              <Route path="/*" element={<AccountPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/account-password" element={<AccountPass />} />
              <Route path="/account-savelists" element={<AccountSavelists />} />
              <Route path="/account-billing" element={<AccountBilling />} />
              <Route path="/my-reservations" element={<MyReservation />} />
            </>
            : status === 'authenticated' && user.rol === 'admin' ?
              <>
                <Route path="/*" element={<PageDashboard />} />
                <Route path="/reservations" element={<PageReservations />} />
                <Route path="/dashboard" element={<PageDashboard />} />
                <Route path="/users" element={<PageUsers />} />
              </>
              :
              <>

                <Route path="/signup" element={<PageSignUp />} />
                <Route path="/login" element={<PageLogin />} />

                <Route path="/*" element={<PageHome />} />
              </>

        }

        <Route path="/#" element={<PageHome />} />
        <Route path="/checkout" element={<CheckOutPage />} />
        <Route path="/pay-done" element={<PayPage />} />
        <Route path="/pay-error" element={<PayError />} />
        <Route path="/reset-password/:token" element={<PageResetPassword />} />
        <Route path="forgot-password" element={<PageForgotPassword />} />
        <Route path="/rooms" element={<PageRoomCalendar />} />
        <Route path="/contact" element={<PageContact />} />
        <Route element={<Page404 />} />
        <Route path="/politicas-cancelacion" element={<PoliticasCancelacion />} />
        <Route path="/politicas-cookies" element={<PagePoliticasCookies />} />
        <Route path="/politicas-privacidad" element={<PagePoliticasPrivacidad />} />
        <Route path="/carta-bebidas" element={<CartaBebidas />} />


      </Routes>

      {WIN_WIDTH < 768 && <FooterNav status={status} startLogout={startLogout} />}
      <Footer />
    </BrowserRouter>

  );
};

export default MyRoutes;
