import { FC, } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import ButtonReservar from "shared/Button/ButtonReservar";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { useReservationStore } from "../../hooks";
import { Link } from "react-router-dom";
import CartaBebidas from "components/CartaBebidas/CartaBebidas";
<link rel="stylesheet" href="https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css"></link>


export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { car } = useReservationStore();

  return (
    <div className={`nc-MainNav1 bg-black relative z-10 ${className}`}>

      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className=" md:flex justify-center flex-1 items-center space-x-4  sm:space-x-10  ">
          <Navigation />

          <Logo />
          <Link to={'/carta-bebidas'} ref={CartaBebidas} className="py-2 px-4 xl:px-5 hidden lg:inline-flex text-sm xl:text-base font-semibold text-purple-700 hover:text-purple-600 uppercase">Carta bebidas</Link>


        </div>
        {/*        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div> */}
        <div className="relative">
          <MenuBar />
          {/* Muestra el contador de artículos solo si hay artículos en el carrito */}
          {car.length > 0 && (
            <div className="bg-purple-600 text-white w-5 h-5 text-sm rounded-full flex items-center justify-center absolute right-2 top-8">
              {car.length}
            </div>
          )}
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center  justify-end flex-1 lg:flex-none text-neutral-600 dark:text-neutral-100">


          <div className="hidden xl:flex items-center space-x-0.5 ">
            {/* <SwitchDarkMode /> */}
            {/*<SearchDropdown />*/}
            <div className="px-1" />
            <ButtonReservar href="/rooms">RESERVAR SALA</ButtonReservar>


          </div>


        </div>
      </div>
    </div>
  );
};

export default MainNav1;
