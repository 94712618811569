import {createSlice} from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLogin: false,
        status: 'checking',
        user:{},
        errorMessage: undefined,
      
    },
    reducers: {
    
        onChecking: (state) => {
            state.status = 'checking';
            state.user = {};
            state.errorMessage = undefined;
        },
        onLogin(state,{payload}) {
            //console.log('Onlogin',payload);
            state.status = 'authenticated';
            state.user = payload;
            state.errorMessage = undefined;
        },
        onLogout(state,{payload}) {
            state.status = 'not-authenticated';
            state.user = {};
            state.errorMessage = payload;
        },
        clearErrorMessage(state) {
            state.errorMessage = undefined;
        },
      

       
    },
});

export const {onLogin,onLogout,onChecking,clearErrorMessage} = authSlice.actions;
