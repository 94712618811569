import axios from "axios";
import { getEnvVar } from "helpers";


const { REACT_APP_API_URL } = getEnvVar();
const roomApi = axios.create({
    baseURL: REACT_APP_API_URL,
});

//Todo; Add interceptors
 roomApi.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    config.headers = {
        ...config.headers,
        'x-token' : token
    }
    return config;
 });

export default roomApi;