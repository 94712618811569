import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useForm, useAuthStore } from "hooks";
import Swal from "sweetalert2";



export interface ResetPasswordProps {
  className?: string;
}



const loginFormFields = {
  newPassword: '',
  confirmPassword: '',
};

const PageResetPassword: FC<ResetPasswordProps> = ({ className = "" }) => {

  const { resetPassword, errorMessage } = useAuthStore();
  const [message, setMessage] = useState('');
  const [responseState, setResponse] = useState({ ok: false, message: '' });
  const { token } = useParams();
  const { newPassword, confirmPassword, onInputChange } = useForm(loginFormFields);
  const [hiddenForm, setHiddenForm] = useState(true)

  useEffect(() => {
    if (errorMessage !== undefined) {
      Swal.fire('Error en la autenticación', errorMessage, 'error');
    }
  }, [errorMessage])

  const handleReset = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMessage('');
    if (newPassword.length < 6 || confirmPassword.length < 6) {
      setMessage('La contraseña debe tener al menos 6 caracteres');
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage('Las contraseñas no coinciden');
      return;
    }
    try {
      resetPassword({ password: newPassword, token }).then((response) => {
        ;
        setResponse(response);
        console.log("Responseee", response);
        if (response.ok) {
          setMessage("Se ha realizado correctamente el cambio de constraseña");
          setHiddenForm(false)
        } else {
          setMessage("Ha pasado mucho tiempo, debes solicitar un nuevo password.");
          setHiddenForm(false)
        }
      });


    } catch (error) {
      console.log('Error al cambiar la contraseña', error);
    }
  };



  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Reestablecer Contraseña || Karaoke Room Barcelona</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-xl leading-[105%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Reestablecer Contraseña
        </h2>
        <div className="max-w-md mx-auto space-y-6">

          {/* FORM */}
          {hiddenForm ?
            <>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleReset}>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Nueva contraseña
                  </span>
                  <Input
                    type="text"
                    className="mt-1"
                    name="newPassword"
                    value={newPassword}
                    onChange={onInputChange}

                  />
                </label>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Confirmar Contraseña

                  </span>
                  <Input
                    type="text"
                    className="mt-1"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={onInputChange}

                  />
                </label>
                <ButtonPrimary type="submit" >Restablecer Contraseña</ButtonPrimary>
              </form>

            </>
            :
            <>

            </>
          }

          {message && <p className="text-red-600 text-center text-xl">{message}</p>}


          {/* ==== */}
          {responseState &&
            responseState.ok ? (
            <span className="block text-center text-neutral-700 dark:text-neutral-300">

              <Link to="/login">Loguearme</Link>
            </span>
          ) : (
            <span className="block text-center hover:text-neutral-400 text-neutral-300">
              <Link to="/forgot-password">Solicita nuevo password aqui</Link>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageResetPassword;
