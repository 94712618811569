import { createSlice } from '@reduxjs/toolkit';

export const slotSlice = createSlice({
    name: 'slot',
    initialState: {
        slots: [],
        isLoadingSlots: true,

    },
    reducers: {
        onCheckingSlot: (state) => {

            state.slots = [];
            state.errorMessage = undefined;
        },
        onReserve: (state) => {
            state.status = 'Reservando....';
            state.slots = [];

        },
        onCreateSlot(state, { payload }) {
            state.slots = '';
            //Para que no se agreguen eventos que ya estan en el store



        },
        onLoadSlots(state, { payload = [] }) {

            state.isLoadingEvents = false;
            //console.log("ejecuta onLoadSlots");
            state.slots = payload;
            // payload.forEach( slot => {
            //     const exists = state.slots.some( dbEvent => dbEvent.id === slot.id );
            //     if ( !exists ) {
            //         state.slots.push( slot )
            //     }
            // })

        },
        onDelete(state, { payload }) {

            state.slots = [];

        },



    },
});

export const { onReserve, onCreateSlot, onCheckingSlot, onDelete, onLoadSlots } = slotSlice.actions;

