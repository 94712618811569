import { useDispatch, useSelector } from "react-redux"
import { onLogin, onLogout, onChecking, clearErrorMessage } from "../store";
import { roomApi } from "../api/";

export const useAuthStore = () => {

    const dispatch = useDispatch();
    const { status, user, errorMessage } = useSelector(state => state.auth);

    const startLogin = async ({ email, password }) => {

        dispatch(onChecking());

        try {
            const { data } = await roomApi.post('/auth', { email, password });
            localStorage.setItem('token', data.token);
            //Sirve para ver si el token es valido en lado cliente
            localStorage.setItem('token-init-date', new Date().getTime());
            dispatch(onLogin({ name: data.name, uid: data.uid, email: data.email, phone: data.phone, rol: data.rol, address: data.address, dateBirth: data.dateBirth }));

        } catch (e) {
            dispatch(onLogout('Error en el login'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }

    }
    const resetPassword = async ({ password, token }) => {

        //dispatch(onChecking());

        try {

            const { data } = await roomApi.post(`/auth/reset-password/${token}`, { password });


            return data;
            //dispatch(onLogin({ name: data.name, uid: data.uid, email: data.email, phone: data.phone, rol: data.rol, address: data.address, dateOfBirth: data.dateOfBirth }));

        } catch (e) {
            dispatch(onLogout('Error en el login'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }

    }

    const requestPasswordReser = async ({ email }) => {

        try {
            // eslint-disable-next-line
            const { data } = await roomApi.post('auth/request-password-reset', { email });

        } catch (e) {
            //dispatch(onLogout('Error al actualizar el usuario'));
            console.log("Errors...", e);
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }

    }


    const startUpdateUser = async ({ uid, name, email, phone, address, dateBirth, rol }) => {

        //dispatch(onChecking());

        try {
            //eslint-disable-next-line
            const {data}  = await roomApi.post('/auth/update', { uid, email, name, phone, address, dateBirth, rol });
            console.log("Valor de data:"+ data.msg);
            dispatch(onLogin({ name: name, uid: uid, email: email, phone: phone, address: address, rol: rol, dateBirth: dateBirth }));
            return data.msg;
        } catch (e) {
            //dispatch(onLogout('Error al actualizar el usuario'));
            console.log("Errors...", e);
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);
        }

    }

    const startLogout = () => {
        localStorage.clear();
        dispatch(onLogout());
    }

    const startRegister = async ({ name, email, phone, password }) => {

        dispatch(onChecking());

        try {
            const { data } = await roomApi.post('/auth/new', { name, email, phone, password });
            localStorage.setItem('token', data.token);
            localStorage.setItem('token-init-date', new Date().getTime());
            dispatch(onLogin({ name: data.name, uid: data.uid, email: data.email, phone: data.phone, address: data.address, rol: data.rol }));
        } catch (e) {

            console.log(e.response.data);
            dispatch(onLogout(e.response.data?.message
                || 'Error en el registro'));
            setTimeout(() => {
                dispatch(clearErrorMessage());
            }, 10);

        }
    }
    const checkAuthToken = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(onLogout());
            return false;
        }
        try {
            console.log('Renovando token');
            const { data } = await roomApi.get('/auth/renew');
            localStorage.setItem('token', data.token);
            localStorage.setItem('token-init-date', new Date().getTime());
            console.log('Token renovado',data);
            dispatch(onLogin({ name: data.name, uid: data.uid, email: data.email, phone: data.phone, rol: data.rol, address: data.address, dateBirth: data.dateBirth }));

        } catch (e) {
            localStorage.clear();
            dispatch(onLogout());

        }
    }

    // eslint-disable-next-line
    const Logout = () => {
        dispatch(onLogout());
    }
    return {

        //Propiedades

        errorMessage,
        status,
        user,

        //Métodos
        startLogin,
        startLogout,
        startRegister,
        checkAuthToken,
        startUpdateUser,
        resetPassword,
        requestPasswordReser,
    }
}