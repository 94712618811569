import { createSlice } from '@reduxjs/toolkit';

export const roomSlice = createSlice({
    name: 'rooms',
    initialState: {
        rooms: [],
        isLoadingRooms: true,

    },

    reducers: {
        onLoadRooms(state, action) {
            state.rooms = action.payload;
            state.isLoadingRooms = false;
        }

    },

})

export const { onLoadRooms } = roomSlice.actions;
export default roomSlice.reducer;

