import React from "react";
import MyRouter from "routers/index";
import { Provider } from "react-redux";
import { store } from "store";
import CookieConsentModal from "containers/PagePoliticasCookies/CookieConsentModal";
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <div className=" dark text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <Provider store={store}>
        <MyRouter />
        <Analytics />
        <CookieConsentModal />
      </Provider>
    </div>
  );
}

export default App;
