import { FC, useState, useEffect } from "react";

import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";

import ButtonReservar from "shared/Button/ButtonReservar";



import { useRoomStore } from "hooks";

export interface StayCardProps {
  className?: string;
  data?: StayDataType;
  size?: "default" | "small";
  rooms?: Room;
  //salas?: Sala[];
}

interface Room {
  capacidad: string;
  capacidadMin: string;
  id: string;
  images: Array<{ url: string }>;
  name: string;
  services: Array<{
    a: string;
    b: string;
    c: string;
    d: string;
    e: string;
  }>;
}
/*
interface Sala {
  id: number;
  nombre: string;
  capacidad: number;
  capacidadMin: string; // Nuevo campo agregado 
}*/


// eslint-disable-next-line
const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  // eslint-disable-next-line
  data,
}) => {
  //const salas = useSelector((state) => state.reservationsSlice.salas);
  const [hoveredSala, setHoveredSala] = useState<string | null>(null);
  const { rooms, getRooms } = useRoomStore();

  useEffect(() => {

    getRooms();

  }, []);

  const handleMouseEnter = (salaId: string) => {
    setHoveredSala(salaId);
  };

  const handleMouseLeave = () => {
    setHoveredSala(null);
  };





  return (
    <div
      className={`nc-StayCard min-h-[396px] grid grid-cols-1 gap-3 md:gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 min-w-[300px] relative dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {rooms.map((room: Room) => (
        /*

        */
        <div key={room.id}>
          {room.services.map((service: any, index: number) => (
            <div key={`${room.id}-${index}`}>
              <div className="border-2 border-b-0 border-purple-600 mt-4 rounded-tl-xl rounded-tr-xl">
                <div className="relative w-full">

                  <img src={room.images[0].url} alt={room.name} className="rounded-tl-xl rounded-tr-xl w-full h-[259px] object-cover" />

                  <div className=" absolute left-5 bottom-3 rounded-2xl bg-purple-600 text-sm text-white p-1">
                    <p className="">{service.a}</p>
                  </div>
                </div>
              </div>
              <div className="border-2 md:min-h-[280px] border-t-0 hover:bg-purple-950 border-purple-600 border-l-2 mb-2 rounded-br-xl rounded-bl-xl">
                <div
                  className={`relative overflow-hidden rounded-2xl justify-center ${className}`}
                  onMouseEnter={() => handleMouseEnter(room.id)}
                  onMouseLeave={handleMouseLeave}
                >
                  {hoveredSala === room.id && (
                    <div className="absolute justify-center flex top-24 left-1/2 transform w-full h-8 -translate-x-1/2 -translate-y-1/2">
                      <ButtonReservar href="/rooms" className="w-full min-h-full mt-16 ml-3 mr-3">
                        RESERVAR SALA
                      </ButtonReservar>
                    </div>
                  )}
                  <div
                    className={
                      size === "default" ? "p-3 space-y-1" : "p-3 space-y-2 "
                    }
                  >
                    <div className="space-y-2">
                      <div
                        className={`flex items-center text-purple-500 text-sm space-x-1 ${hoveredSala === room.id ? "invisible" : "visible"
                          }`}
                      >
                        {size === "default" && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={1.5}
                              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={1.5}
                              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}
                        <span className="">Barcelona</span>
                      </div>

                      <div className="flex justify-center py-4 items-center space-x-2 ">
                        {/*isAds && {<Badge name="ADS" color="green" />*/}
                        <h2
                          className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                            }`}
                        >
                          <p className="text-[50px]  text-white font-pump cursor-pointer">
                            {room.name}
                          </p>


                        </h2>
                      </div>


                      <div
                        className={`text-sm flex grid grid-cols-1 justify-center text-purple-500 ${hoveredSala === room.id ? "invisible" : "visible"
                          }`}
                      >
                        <div className="">
                          {service.b && (
                            <p className="rounded-2xl  bg-purple-600 text-center text-sm text-white p-1">
                              {service.b}
                            </p>
                          )}
                          <div className=" grid grid-cols-2  justify-items-center ">
                            {service.d && (
                              <p className="rounded-2xl bg-purple-600 mt-2 text-sm text-white p-1">
                                {service.d}
                              </p>
                            )}
                            {service.c && (
                              <p className="rounded-2xl  whitespace-nowrap  bg-purple-600 mt-2 text-sm text-white p-1">
                                {service.c}
                              </p>
                            )}
                            {service.e && (
                              <p className="rounded-2xl bg-purple-600 mt-2 text-sm text-white p-1">
                                {service.e}
                              </p>
                            )}
                            {service.f && (
                              <p className="rounded-2xl bg-purple-600 mt-2 text-sm text-white p-1">
                                {service.f}
                              </p>
                            )}
                          </div>

                        </div>


                        <div >
                          <i className="las mt-2 la-user text-[20px]"></i>Capacidad: Min/
                          {room.capacidadMin} pers. Max/{room.capacidad} pers.
                        </div>

                      </div>
                    </div>

                    <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                    <div className="flex justify-center items-center">
                      <span
                        className={`text-sm text-purple-500 ${hoveredSala === room.id ? "invisible" : "visible"
                          }`}
                      >
                        {` `}
                        {size === "default" && (
                          <span className="text-sm text-purple-500 font-normal">
                            Desde 10€/ Persona / 1 h 45 min
                          </span>
                        )}
                      </span>
                      {/*!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default StayCard;
