import { FC } from "react";
import merch from "images/merch1.jpg";

export interface SectionMerchProps {
    className?: string;
}

const SectionMerch: FC<SectionMerchProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-SectionHero3  relative ${className}`}
            data-nc-id="SectionHero3"
            id="container-hero3"
        >

            <div className="absolute w-1/2 z-10 ml-10 mt-20 flex flex-col">
                <h2 className="text-left font-bold text-4xl sm:text-4xl md:text-4xl lg:text-8xl xl:text-8xl">
                    MERCHANDISING
                    INOLVIDABLE
                </h2>
                <span className="w-80 text-xl">Regala un recuerdo inolvidable a la estrella de la noche!</span>
            </div>


            <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-3 sm:aspect-h-3 lg:aspect-w-12 lg:aspect-h-8 xl:aspect-h-4">

                <img
                    id="banner"
                    className="absolute rounded-3xl object-cover  object-right"
                    src={merch}
                    alt="hero"
                />
            </div>
        </div>
    );
};

export default SectionMerch;
