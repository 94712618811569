import { FC, useState, Fragment } from "react";
import StayCard from "components/StayCard/StayCard";
import {

  DEMO_STAY_LISTINGS,
} from "data/listings";
import { StayDataType } from "data/types";

import Heading2 from "components/Heading/Heading2";

import ButtonReservar from "shared/Button/ButtonReservar";
import { Tab } from "@headlessui/react";


import Proximamente from "components/StayCard/Proximamente";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 4);

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  // eslint-disable-next-line
  data = DEMO_DATA,
}) => {
  const [categories] = useState(["Barcelona", "Ibiza", "Madrid"]);
  

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 />

      <Tab.Group>
        <Tab.List className="flex justify-center space-x-1 overflow-x-auto">
          {categories.map((item) => (
            <Tab key={item} as={Fragment}>
              {({ selected }) => (
                <button
                  className={`flex-shrink-0 block !leading-none font-bold px-5 py-2.5 text-sm sm:text-2xl text-white sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                    selected
                      ? "bg-secondary-900 hover:text-white text-secondary-50 "
                      : "dark:text-purple-600 dark:hover:text-white dark:hover:bg-neutral-800"
                  } `}
                >
                  {item}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="mt-8">
            <div className="">
              <StayCard />
            </div>
            <div className="flex mt-16 justify-center items-center">
              <ButtonReservar href="/rooms">RESERVAR SALA</ButtonReservar>
            </div>
          </Tab.Panel>
          <Tab.Panel className="mt-8">
            <Proximamente />
          </Tab.Panel>
          <Tab.Panel className="mt-8">
            {/* <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 8).map((stay) => (
                <ExperiencesCard key={stay.id} data={stay} />
              ))}
            </div>
              */}
            <Proximamente />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default SectionGridFilterCard;

/*
      <div className="grid grid-cols-1 gap-3 md:gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data.map((stay) => (
          <StayCard key={stay.id} data={stay} />
        ))}
      </div>

      <div className="flex mt-16 justify-center items-center">
        <ButtonReservar href="/rooms">RESERVAR SALA</ButtonReservar>
      </div>
    </div>
*/
