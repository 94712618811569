import { StarIcon } from "@heroicons/react/24/solid";
import { FC, useState } from "react";
import "./CommentListing.scss";

interface CommentListingDataType {
  name: string;
  avatar?: string;
  date: string;
  comment: string;
  starPoint: number;
}

export interface CommentListingProps {
  className?: string;
  data?: CommentListingDataType;
  hasListingTitle?: boolean;
}

const DEMO_DATA: CommentListingDataType[] = [
  {name: "Esther",
  date: "",
  comment:
    "“El martes fuimos toda la familia a celebrar el cumpleaños de una hija. El lugar y la decoración es espectacular, cómo estar en las Vegas con el Rat Pack ...”",
  starPoint: 5,
  },
  {
  name: "Amelia",
  date: "",
  comment:"Fuimos aquí a celebrar mi cumpleaños y no puedo estar más contenta con el trato que hemos recibido.",
  starPoint: 5,
  },
  {
    name: "Daniel",
    date: "",
    comment:"“Totalmente recomendable. Fuimos un grupo de 12 personas y todo genial. La atención prestada de 10. Catálogo extenso de canciones. Volveremos seguro”",
    starPoint: 5,
    }
];

const CommentListing: FC<CommentListingProps> = ({
  className = "",
  data = DEMO_DATA[0], // Aparecera el comentario 0 en la pantalla
  hasListingTitle,
}) => {

  const [currentCommentIndex, setCurrentCommentIndex] = useState(0);


  const nextComment = () => {
    setCurrentCommentIndex((prevIndex) =>
      prevIndex < DEMO_DATA.length - 1 ? prevIndex + 1 : 0
    );

  };

  const previousComment = () => {
    setCurrentCommentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : DEMO_DATA.length - 1
    );

  };

  const currentComment = DEMO_DATA[currentCommentIndex];


  return (
    <div
      className={`nc-CommentListing w-full flex items-center shrink justify-center ${className}`}
      data-nc-id="CommentListing"
    >
      <div className="flex space-x-4 transition-transform items-center ease-in-out duration-300">
      
        <div>
          <button className="hidden sm:block" onClick={previousComment}>
            <i className="las la-arrow-left text-purple-900 text-3xl"></i>
          </button>
        </div>

        <div className="min-h-[300px] w-full sm:w-[705px] md:w-[705px] lg:w-[705px]">
          {/*<div className="flex justify-between space-x-3">
          <div className="flex p-5">
            <div className="text-sm font-semibold">

              {hasListingTitle && (
                <>
                  <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                    {` review in `}
                  </span>
                  <a href="/">The Lounge & Bar</a>
                </>
              )}
            </div>

          </div>
        </div>*/}
          <div className="flex justify-center items-center">
            <span className="text-center font-bold text-2xl sm:text-3xl md:text-5xl lg:text-5xl xl:text-5xl mt-3 text-purple-900 ">
              {currentComment.comment}
            </span>
          </div>

          <div className="pt-0.5 mt-10 justify-center flex">
            {/* <Avatar
              sizeClass="h-10 w-10 text-lg"
              radius="rounded-full"
              userName={currentComment.name}
              imgUrl={currentComment.avatar}
            /> */}
            <div className="flex flex-col items-start ml-2">
              <div className="flex text-yellow-500">
                {[...Array(currentComment.starPoint)].map((_, index) => (
                  <StarIcon key={index} className="w-3 h-3" />
                ))}
              </div>
              <span className="text-xl text-purple-600 mt-2">
                {currentComment.name}
              </span>
              <span className="text-sm text-purple-600">
                {currentComment.date}
              </span>
            </div>
          </div>
        </div>

        <div>
          <button className="hidden sm:block" onClick={nextComment}>
            <i className="las la-arrow-right text-purple-900 text-3xl"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommentListing;
