import CookieConsent from "react-cookie-consent"

const CookieConsentModal = () => {
    return (
        <>

            <CookieConsent
                location="bottom"
                buttonText="Aceptar"
                cookieName="krmCookie"
                style={{ background: "#f0f0f06e", color: "black", backdropFilter: "blur(6px)" }}
                buttonStyle={{  color: "white", fontSize: "16px", backgroundColor: "#8F00FF", borderRadius:"20px" }}
                enableDeclineButton flipButtons
                declineButtonText="Configurar cookies"
                declineButtonStyle={{borderRadius:"20px", fontSize: "16px"}}
                expires={150}

            >
                Este portal web únicamente utiliza cookies propias con finalidad técnica,
                no recaba ni cede datos de carácter personal de los usuarios sin su
                conocimiento. Sin embargo, contamos con una <a className=" text-purple-800 underline" href="/politicas-cookies">Política de Cookies</a> que queremos
                que conozcas.
            </CookieConsent>

        </>
    )
}

export default CookieConsentModal