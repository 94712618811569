const PagePoliticasCookies = () => {
  return (
    <div className="text-[#6b7280]">
      <h1 className=" text-5xl text-purple-700 text-center font-bold mt-10 md:m-20">
        Politicas De Cookies
      </h1>

      <div>
        {" "}
        <div className=" justify-center md:justify-start md:w-2/3 text-xl m-10 overflow-x-hidden">
          <span className=" text-purple-700 uppercase text-3xl font-bold">
            ¿Qué son las cookies?
          </span>
          <br />
          <span>
            <p>Cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario. El navegador del usuario memoriza cookies en el disco duro solamente durante la sesión actual ocupando un espacio de memoria mínimo y no perjudicando al ordenador. Las cookies no contienen ninguna clase de información personal específica, y la mayoría de las mismas se borran del disco duro al finalizar la sesión de navegador (las denominadas cookies de sesión).</p>
            <br />
            <p>
              La mayoría de los navegadores aceptan como estándar a las cookies y, con independencia de las mismas, permiten o impiden en los ajustes de seguridad las cookies temporales o memorizadas.
            </p>
            <br />
            <p>
              Sin su expreso consentimiento –mediante la activación de las cookies en su navegador– este sitio web no enlazará en las cookies los datos memorizados con sus datos personales proporcionados en el momento del registro o la compra.

            </p>

          </span>


          <div className="mt-10">
            {" "}
            <p className=" text-purple-700 text-3xl font-bold ">
              ¿QUÉ TIPOS DE COOKIES UTILIZAMOS EN ESTE SITIO WEB?{" "}
            </p>
            <p>
              Este sitio web utiliza cookies que permiten el funcionamiento y la prestación de los servicios ofrecidos en el mismo.
            </p>
            <br />
            <p>
              Siguiendo las directrices de la Agencia Española de Protección de Datos procedemos a detallar el uso de cookies que hace esta web con el fin de informarle con la máxima exactitud posible.
            </p>

            <br /><br />

            <p>
              ➡️Este sitio web utiliza las siguientes <span className=" font-bold">cookies propias:</span>

            </p>

            <br />

            <p>
              <section className="  text-purple-700 font-bold">Cookies técnicas:</section> son aquellas que permiten al usuario la navegación a
              través de una página web, plataforma o aplicación y la utilización
              de las diferentes opciones o servicios que en ella existan, incluyendo
              aquellas que el editor utiliza para permitir la gestión y operativa
              de la página web y habilitar sus funciones y servicios, como, por ejemplo,
              controlar el tráfico y la comunicación de datos, identificar la sesión,
              acceder a partes de acceso restringido, recordar los elementos que integran
              un pedido, realizar el proceso de compra de un pedido, gestionar el pago,
              controlar el fraude vinculado a la seguridad del servicio, realizar la
              solicitud de inscripción o participación en un evento, contar visitas a
              efectos de la facturación de licencias del software con el que funciona
              el servicio (sitio web, plataforma o aplicación), utilizar elementos de
              seguridad durante la navegación, almacenar contenidos para la difusión
              de vídeos o sonido, habilitar contenidos dinámicos (por ejemplo, animación
              de carga de un texto o imagen) o compartir contenidos a través de redes sociales.
            </p>

            <br />

            <p>
              <section className="  text-purple-700 font-bold">Cookies de personalización:</section>
              son aquellas que permiten recordar información para que el usuario
              acceda al servicio con determinadas características que pueden diferenciar
              su experiencia de la de otros usuarios, como, por ejemplo, el idioma, el
              número de resultados a mostrar cuando el usuario realiza una búsqueda, el
              aspecto o contenido del servicio en función del tipo de navegador a través
              del cual el usuario accede al servicio o de la región desde la que accede al
            </p>
            <br /><br />

            <p>
              ➡️Este sitio web utiliza las siguientes <span className=" font-bold">cookies de terceros:</span>

            </p>
            <br />

            <p>
              <section className="  text-purple-700 font-bold">Google Analytics:</section>
              Almacena cookies para poder elaborar estadísticas sobre el tráfico y volumen de visitas
              de esta web. Al utilizar este sitio web está consintiendo el tratamiento de información
              acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho en este sentido
              deberá hacerlo comunicándo directamente con Google.

            </p>

            <br />

            <p>
              <section className="  text-purple-700 font-bold">Redes Sociales:</section>
              Cada red social utiliza sus propias cookies para que usted pueda pinchar en botones
              del tipo Me gusta o Compartir.

            </p>

          </div>
          <div className="mt-10 ">
            {" "}
            <p className=" text-purple-700 md:text-3xl text-center font-bold ">
              RESUMEN COOKIES PROPIAS Y DE TERCEROS UTILIZADAS EN ESTE PORTAL WEB
            </p>

            <div className=" overflow-auto">
              <table className="md:min-w-full mt-5 border border-white">
                <tr className=" text-sm md:text-lg text-purple-600">
                  <th className="border border-white md:px-6 md:py-2 ">Tipo de cookie</th>
                  <th className="border border-white px-6 py-2 ">Titularidad</th>
                  <th className="border border-white px-6 py-2 ">Identificación/nombre de la cookie</th>
                  <th className="border border-white px-6 py-2 ">Periodo de conservación</th>
                  <th className="border border-white px-6 py-2 ">Propósito/Finalidad</th>
                </tr>

                <tr className=" text-base text-center">
                  <td className="border border-white">Técnica</td>
                  <td className="border border-white">Tercero: Google</td>
                  <td className="border border-white">UULE</td>
                  <td className="border border-white">1 día</td>
                  <td className="border border-white p-2">Cookie asociada al servicio de Google Maps. Permite la geolocalización de nuestra Clínica en la sección correspondiente de este sitio web</td>
                </tr>

                <tr className=" text-base text-center">
                  <td className="border border-white">Técnica</td>
                  <td className="border border-white">Propia</td>
                  <td className="border border-white">viewed_cookie_policy</td>
                  <td className="border border-white">1 día</td>
                  <td className="border border-white p-2">Cookies de uso interno necesaria para el funcionamiento de la visualización de la política de cookies de la web.</td>
                </tr>

              </table>

            </div>


          </div>
          <div className="mt-10">
            {" "}
            <p className=" text-purple-700 text-3xl font-bold ">
              DESACTIVACIÓN O ELIMINACIÓN DE COOKIES
            </p>
            <p>
              Al desactivar cookies, algunos de los servicios disponibles podrían dejar de estar operativos. La forma de deshabilitar las cookies es diferente para cada navegador, pero normalmente puede hacerse desde el menú Herramientas u Opciones. También puede consultarse el menú de Ayuda del navegador dónde puedes encontrar instrucciones. El usuario podrá en cualquier momento elegir qué cookies quiere que funcionen en este sitio web.
            </p><br />
            <p>
              Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador:
            </p><br />

            <p>
              Microsoft Internet Explorer o Microsoft Edge: <br />
              <a className="text-purple-400 hover:text-cyan-400" href="http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies" target="blank">http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies </a>
            </p><br />

            <p>
              Mozilla: <br />
              <a className="text-purple-400 hover:text-cyan-400" href="http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia" target="blank">http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia</a>
            </p><br />

            <p>
              Chrome: <br />
              <a className="text-purple-400 hover:text-cyan-400" href="https://support.google.com/accounts/answer/61416?hl=es" target="blank">https://support.google.com/accounts/answer/61416?hl=es</a>
            </p><br />

            <p>
              Safari: <br />
              <a className="text-purple-400 hover:text-cyan-400" href="http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/" target="blank">http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/</a>
            </p><br />

            <p>
              Opera: <br />
              <a className="text-purple-400 hover:text-cyan-400" href="http://help.opera.com/Linux/10.60/es-ES/cookies.html" target="blank">http://help.opera.com/Linux/10.60/es-ES/cookies.html</a>
            </p><br />

            <p>
              Safari para iOS (dispositivos iPhone y iPad): <br />
              <a className="text-purple-400 hover:text-cyan-400" href="http://help.opera.com/Linux/10.50/en/cookies.html" target="blank">http://help.opera.com/Linux/10.50/en/cookies.html</a>
            </p><br />

            <p>
              Chrome para Android: <br />
              <a className="text-purple-400 hover:text-cyan-400" href="https://support.google.com/chrome/answer/2392971?hl=es" target="blank">https://support.google.com/chrome/answer/2392971?hl=es</a>
            </p><br />

            <p>
              Windows Phone: <br />
              <a className="text-purple-400 hover:text-cyan-400" href="http://www.windowsphone.com/es-es/how-to/wp7/web/changing-privacy-and-other-browser- " target="blank">http://www.windowsphone.com/es-es/how-to/wp7/web/changing-privacy-and-other-browser-</a>
            </p><br />

            <p>
              Además, también puede gestionar el almacén de cookies en su navegador a través de herramientas como las siguientes:
            </p><br />

            <p>
              Ghostery: <br />
              <a className="text-purple-400 hover:text-cyan-400" href="http://www.ghostery.com" target="blank">www.ghostery.com</a>
            </p><br />

            <p>
              Your online choices: <br />
              <a className="text-purple-400 hover:text-cyan-400" href="http://www.youronlinechoices.com/es/ " target="blank"> www.youronlinechoices.com/es/ </a>
            </p><br />

          </div>
          <div className="mt-10">
            <p>
              Mas información relativa a la protección de sus datos personales en nuestra <a className=" text-purple-400" href="/politicas-privacidad">Política de Privacidad.</a>
              El editor se reserva el derecho de cambiar esta política de cookies si se realizan cambios en la configuración y/o uso de las mismas. Se publicará una versión actualizada en esta página web.

            </p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PagePoliticasCookies;
