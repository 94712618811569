import { FC } from "react";

import mapa from "images/ubicacion-hospitalet-karaoke.png";


export interface MapUbicationProps {
  className?: string;
}

const MapUbication: FC<MapUbicationProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-MapUbication relative ${className}`}
      data-nc-id="MapUbication"
      id="container-mapubication"
    >
      <div className="z-10 inset-x-0 top-[10%] sm:top-[15%] text-center flex flex-col items-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-8">
        <h2 className="font-extrabold dark:text-white text-black text-6xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl !leading-[115%]">
          DÓNDE ESTAMOS
        </h2>
        <h2 className="text-purple-700 text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl">
          📍Av. Del carrilet, 237, 08907 <br />L’Hospitalet de Llobregat, Barcelona
        </h2>


      </div>
      <div className="relative aspect-w-1 aspect-h-1 mt-10  sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-12 lg:aspect-h-8 xl:aspect-h-4">
        <img
          id="mapa"
          className="inset-0 object-cover  "
          src={mapa}
          alt="Mapa Karaoke Barcelona"

        />
      </div>
    </div>
  );
};

export default MapUbication;
