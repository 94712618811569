import CalendarRoom from "components/CalendarRoom/CalendarRoom";

import { FC } from "react";


export interface SalaHorario {
  id: number;
  roomId: string;
  slotsHorario: {
    date?: string;
    startTime: string;
    endTime: string;
    price: string;
  }[];
  capacidad: number; // Nuevo campo agregado
  ubicacion: string; // Nuevo campo agregado
}

export interface PropsRoomCalendar {
  data?: SalaHorario[];

}


const PageRoomCalendar: FC<PropsRoomCalendar> = () => {


  return (
    <div>
      <CalendarRoom />
    </div>
  );
};
export default PageRoomCalendar;
