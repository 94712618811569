import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";

import { ChevronDownIcon } from "@heroicons/react/24/solid";

import InformacionCarrito from "./InformacionCarrito";


import { useReservationStore } from "../../hooks";


<link
  rel="stylesheet"
  href="https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css"
></link>;

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;


}

const NavMobile: React.FC<NavMobileProps> = ({
  // eslint-disable-next-line
  data = NAVIGATION_DEMO,
  onClickClose,

}) => {
  const { car } = useReservationStore();




  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              end
              to={{
                pathname: i.href || undefined,
              }}
              className={({ isActive }) =>
                `flex px-4 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 ${isActive ? "text-secondary" : ""
                }`
              }
            >
              <span
                className={`py-2.5 pr-3 ${!i.children ? "block w-full" : ""}`}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="flex-1 flex"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="py-2.5 flex justify-end flex-1"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  // eslint-disable-next-line
  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure
        key={item.id}
        as="li"
        className="text-neutral-900 dark:text-white"
      >
        {/*<NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive ? "text-secondary" : ""
            }`
          }
          to={{
            pathname: item.href || undefined,
          }}
        >
          <span
            className={`py-2.5 pr-3 ${!item.children ? "block w-full" : ""}`}
          >
            {item.name}
          </span>
          {item.children && (
            <span className="flex-1 flex" onClick={(e) => e.preventDefault()}>
              <Disclosure.Button
                as="span"
                className="py-2.5 flex items-center justify-end flex-1 "
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
          </NavLink>*/}

        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  return (
    <div
      className="overflow-y-auto overflow-x-hidden w-full h-screen py-2 transition transform shadow-lg ring-1"
      style={{ backgroundColor: "#E5E7EB" }}
    >
      <div className="py-6 px-5 relative text-center">
        {/* Clase mx-auto para centrar horizontalmente */}
        <Logo />

        <div className="absolute right-36 top-4 p-1 hidden sm:block">
          <i className="las la-shopping-bag text-purple-700" style={{ fontSize: '32px' }}></i>
          {car.length > 0 && (
            <div className="bg-purple-600 text-white w-5 h-5 text-sm rounded-full flex items-center justify-center absolute right-0 top-7">
              <span>{car.length}</span>
            </div>
          )}

        </div>

        {/* <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-700 text-sm">
          <div className="flex justify-between items-center ">
            <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-100 dark:text-neutral-700" />
            <span className="block">
              <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-100 dark:text-neutral-700 " />
            </span>
          </div>
        </div>*/}

        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>

      {/*data.map(_renderItem)*/}

      <div className="mt-0 xl:ml-16 ml-2">
        {car.length === 0 ? (
          <div className="flex mt-[50%] md:mt-0 justify-center items-center text-3xl">
            <span className="text-gray-900 font-bold">
              Su carrito está vacío...
            </span>
          </div>
        ) : (
          <>
            <div className="mb-10 text-2xl md:text-3xl text-center">
              <span className="text-gray-900  ml-0 font-bold">
                Prepárate para cantar 🎤🎵🎵
              </span>
            </div>
            <br />
            <span className="text-gray-900 text-2xl xl:ml-5 ml-0 font-bold mb-5">
              Tu Reserva:
            </span>
            <InformacionCarrito />

          </>
        )}
      </div>
    </div>
  );
};

export default NavMobile;
